import FUR1 from '../src/media/Furniture/Final Images/Bar Trolley-/Circular Bar Trolley - Black Finish/FUR1.jpg' ; 
import FUR2 from '../src/media/Furniture/Final Images/Bar Trolley-/Circular Bar Trolley - Black Finish/FUR2.jpg' ; 
import FUR3 from '../src/media/Furniture/Final Images/Bar Trolley-/Circular Bar Trolley - Gold Finish/FUR3.jpg'
import FUR4 from '../src/media/Furniture/Final Images/Bar Trolley-/Circular Bar Trolley - Gold Finish/FUR4.jpg'
import FUR5 from '../src/media/Furniture/Final Images/Bar Trolley-/Cylindrical Bar Trolley - Gold Finish/FUR5.jpg'
import FUR6 from '../src/media/Furniture/Final Images/Bar Trolley-/Cylindrical Bar Trolley - Gold Finish/FUR6.jpg'
import FUR7 from '../src/media/Furniture/Final Images/Bar Trolley-/Three layer Bar Trolley - Gold Finish/FUR7.jpg'
import FUR8 from '../src/media/Furniture/Final Images/Bar Trolley-/Three layer Bar Trolley - Gold Finish/FUR8.jpg'
import FUR9 from '../src/media/Furniture/Final Images/Bar Trolley-/Two Layer Bar Trolley - Gold Finish/FUR9.jpg'
import FUR10 from '../src/media/Furniture/Final Images/Bar Trolley-/Two Layer Bar Trolley - Gold Finish/FUR10.jpg'
import FUR11 from '../src/media/Furniture/Final Images/Center Tables-/Bone Inlay Center Tables-/Black & White Pattern/7-Sided Black & White Bone Inlay Center Table/FUR11.jpg'
import FUR12 from '../src/media/Furniture/Final Images/Center Tables-/Bone Inlay Center Tables-/Black & White Pattern/7-Sided Black & White Bone Inlay Center Table/FUR12.jpg'
import FUR13 from '../src/media/Furniture/Final Images/Center Tables-/Bone Inlay Center Tables-/Black & White Pattern/8-Sided Bone Inlay Center Table/FUR13.jpg' ; 
import FUR14 from '../src/media/Furniture/Final Images/Center Tables-/Bone Inlay Center Tables-/Black & WHite Stripes Bone Inlay Center Table/FUR14.jpg'
import FUR15 from '../src/media/Furniture/Final Images/Center Tables-/Bone Inlay Center Tables-/Black & WHite Stripes Bone Inlay Center Table/FUR15.jpg'
import FUR16 from '../src/media/Furniture/Final Images/Center Tables-/Bone Inlay Center Tables-/Circular Bone Inaly Center Table/Black & White Design Bone Inlay Center Table/FUR16.jpg' ; 
import FUR17 from '../src/media/Furniture/Final Images/Center Tables-/Bone Inlay Center Tables-/Circular Bone Inaly Center Table/Black & White Design Bone Inlay Center Table/FUR17.jpg' ; 
import FUR18 from '../src/media/Furniture/Final Images/Center Tables-/Bone Inlay Center Tables-/Circular Bone Inaly Center Table/Blue & White Design Bone Inaly Center Table/FUR18.jpg' ; 
import FUR19 from '../src/media/Furniture/Final Images/Center Tables-/Bone Inlay Center Tables-/Circular Bone Inaly Center Table/Blue & White Design Bone Inaly Center Table/FUR19.jpg' ; 
import FUR20 from '../src/media/Furniture/Final Images/Center Tables-/Bone Inlay Center Tables-/Circular Bone Inaly Center Table/Geomertic Design Bone Inlay Center Table/FUR20.jpg' ; 
import FUR21 from '../src/media/Furniture/Final Images/Center Tables-/Bone Inlay Center Tables-/Circular Bone Inaly Center Table/White Floral Design Bone Inlay Center Table/FUR21.jpg' ; 
import FUR22 from '../src/media/Furniture/Final Images/Center Tables-/Bone Inlay Center Tables-/Circular Bone Inaly Center Table/White Floral Design Bone Inlay Center Table/FUR22.jpg'
import FUR23 from '../src/media/Furniture/Final Images/Center Tables-/Bone Inlay Center Tables-/Oval Shaped Bone Inlay Center Table/FUR23.jpg' ; 
import FUR24 from '../src/media/Furniture/Final Images/Center Tables-/Bone Inlay Center Tables-/Oval Shaped Bone Inlay Center Table/FUR24.jpg' ; 
import FUR25 from '../src/media/Furniture/Final Images/Center Tables-/Bone Inlay Center Tables-/White Floral Print Rectangular Bone Inlay Center Table/FUR25.jpg' ; 
import FUR26 from '../src/media/Furniture/Final Images/Center Tables-/Bone Inlay Center Tables-/White Floral Print Rectangular Bone Inlay Center Table/FUR26.jpg' ; 
import FUR27 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Circular Base Center Table/FUR27.jpg' ; 
import FUR28 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Circular Base Center Table/FUR28.jpg' ; 
import FUR30 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 2 Circular Nesting Center Table/Set of 2 Nesting Center Table - Black Marble Gold Finish/FUR30.jpg' ; 
import FUR31 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 2 Circular Nesting Center Table/Set of 2 Nesting Center Table - Black Marble Gold Finish/FUR31.jpg' ; 
import FUR32 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 2 Circular Nesting Center Table/Set of 2 Nesting Center Table - Black Marble Gold PVD Finish/FUR32.jpg' ; 
import FUR33 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 2 Circular Nesting Center Table/Set of 2 Nesting Center Table - Black Marble Gold PVD Finish/FUR33.jpg' ; 
import FUR34 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 2 Circular Nesting Center Table/Set of 2 Nesting Center Table - White Marble Black Finish/FUR34.jpeg' ;
import FUR35 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 2 Circular Nesting Center Table/Set of 2 Nesting Center Table - White Marble Black Finish/FUR35.jpg' ;
import FUR36 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 2 Circular Nesting Center Table/Set of 2 Nesting Center Table - White Marble Gold PVD Finish/FUR36.jpg'
import FUR37 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 2 Circular Nesting Center Table/Set of 2 Nesting Center Table - White Marble Gold PVD Finish/FUR37.jpg'
import FUR38 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 2 Simple Nesting Center Table/Set of 2 Black Marble Nesting Table - Black Finish/FUR38.jpeg' ; 
import FUR39 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 2 Simple Nesting Center Table/Set of 2 Black Marble Nesting Table - Black Finish/FUR39.jpg'
import new1 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 2 Simple Nesting Center Table/Set of 2 White Marble Nesting Table - Black Finish/new1.jpg';
import new2 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 2 Simple Nesting Center Table/Set of 2 White Marble Nesting Table - Black Finish/new2.jpeg';
import FUR40 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 2 Square Nesting Table/Set of 2 Nesting center Table - Black Finish/FUR40.jpeg' 
import FUR41 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 2 Square Nesting Table/Set of 2 Nesting center Table - Black Finish/FUR41.jpeg' 
import FUR42 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 2 Square Nesting Table/Set of 2 Nesting Center Table - Gold Finish/FUR42.jpg' ;
import FUR43 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 2 Square Nesting Table/Set of 2 Nesting Center Table - Gold Finish/FUR43.jpeg' ;
import FUR45 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 3 Nesting Center Table/FUR45.jpeg'
import FUR46 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 3 Nesting Center Table/FUR46.jpeg'
import FUR47 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 3 Square Nesting Table/FUR47.jpeg'
import FUR48 from '../src/media/Furniture/Final Images/Center Tables-/Iron Center Tables-/Set of 3 Square Nesting Table/FUR48.jpeg'
import FUR50 from '../src/media/Furniture/Final Images/Center Tables-/Stainless Steel Center Tables-/Multiple Sphere Base Center Table/FUR50.jpg'
import FUR51 from '../src/media/Furniture/Final Images/Center Tables-/Stainless Steel Center Tables-/Multiple Sphere Base Center Table/FUR51.jpeg'
import FUR52 from '../src/media/Furniture/Final Images/Center Tables-/Stainless Steel Center Tables-/Oval & Circular Set of 2 Center Table/FUR52.jpg'
import FUR53 from '../src/media/Furniture/Final Images/Center Tables-/Stainless Steel Center Tables-/Oval & Circular Set of 2 Center Table/FUR53.jpeg'
import FUR54 from '../src/media/Furniture/Final Images/Center Tables-/Stainless Steel Center Tables-/Rectangular Center Table with 2 Semi Circular Nesting Table/FUR54.jpeg' ; 
import FUR55 from '../src/media/Furniture/Final Images/Center Tables-/Stainless Steel Center Tables-/Set of 2 Stainless Steel Nesting Table - Gold Finish/FUR55.png' ; 
import FUR56 from '../src/media/Furniture/Final Images/Center Tables-/Stainless Steel Center Tables-/Set of 2 Stainless Steel Nesting Table - Gold Finish/FUR56.jpeg' ; 
import FUR57 from '../src/media/Furniture/Final Images/Center Tables-/Stainless Steel Center Tables-/Set of 2 Stainless Steel Nesting Table - Silver Finish/FUR57.jpeg' ; 
import FUR58 from '../src/media/Furniture/Final Images/Center Tables-/Stainless Steel Center Tables-/Set of 2 Stainless Steel Nesting Table - Silver Finish/FUR58.jpeg' ; 
import FUR59 from '../src/media/Furniture/Final Images/Center Tables-/Stainless Steel Center Tables-/Set of 2 Stainless Steel Rose Gold Finish/FUR59.jpeg' ; 
import FUR60 from '../src/media/Furniture/Final Images/Center Tables-/Stainless Steel Center Tables-/Set of 3 Nesting Table - Gold Finish/FUR60.jpg' ; 
import FUR61 from '../src/media/Furniture/Final Images/Center Tables-/Stainless Steel Center Tables-/Set of 3 Nesting Table - Gold Finish/FUR61.jpeg' ; 
import FUR62 from '../src/media/Furniture/Final Images/Console Tables-/Bone Inlay Console Table/Blue & White Floral Print Console Table/FUR62.jpg' ; 
import FUR63 from '../src/media/Furniture/Final Images/Console Tables-/Bone Inlay Console Table/Four Drawer Bone Inlay Console Table/FUR63.png';
import FUR64 from '../src/media/Furniture/Final Images/Console Tables-/Bone Inlay Console Table/Four Drawer Bone Inlay Console Table/FUR64.jpg';
import FUR65 from '../src/media/Furniture/Final Images/Console Tables-/Bone Inlay Console Table/Three Drawer Bone Inlay Console Table/FUR65.jpg' ; 
import FUR66 from '../src/media/Furniture/Final Images/Console Tables-/Bone Inlay Console Table/Three Drawer Bone Inlay Console Table/FUR66.jpg' ; 
import FUR67 from '../src/media/Furniture/Final Images/Console Tables-/Bone Inlay Console Table/Three Drawer White Floral Print Console Table/FUR67.jpg'
import FUR68 from '../src/media/Furniture/Final Images/Console Tables-/Bone Inlay Console Table/Three Drawer White Floral Print Console Table/FUR68.jpg'
import FUR69 from '../src/media/Furniture/Final Images/Console Tables-/Bone Inlay Console Table/Two Drawer White Floral Print Console Table/FUR69.jpg'
import FUR70 from '../src/media/Furniture/Final Images/Console Tables-/Bone Inlay Console Table/Two Drawer White Floral Print Console Table/FUR70.jpg'
import FUR71 from '../src/media/Furniture/Final Images/Console Tables-/Metal Console Table/Circular Ring Design Console Table/FUR71.jpg'
import FUR72 from '../src/media/Furniture/Final Images/Console Tables-/Metal Console Table/Circular Ring Design Console Table/FUR72.jpg' 
import FUR73 from '../src/media/Furniture/Final Images/Console Tables-/Metal Console Table/Cross Base Console Table/FUR73.jpg'; 
import FUR74 from '../src/media/Furniture/Final Images/Console Tables-/Metal Console Table/Cross Base Console Table/FUR74.jpg'; 
import FUR75 from '../src/media/Furniture/Final Images/Console Tables-/Metal Console Table/Rectangular Console Table/FUR75.jpg'; 
import FUR76 from '../src/media/Furniture/Final Images/Console Tables-/Metal Console Table/U-Shaped Console Table/FUR76.jpg'; 
import FUR77 from '../src/media/Furniture/Final Images/Console Tables-/Metal Console Table/U-Shaped Console Table/FUR77.jpg'; 
import FUR78 from '../src/media/Furniture/Final Images/Dining Tables-/Curved Metal Legs with Center Ring Dining Table/FUR78.png'
import FUR79 from '../src/media/Furniture/Final Images/Dining Tables-/Curved Metal Legs with Center Ring Dining Table/FUR79.jpg'
import FUR80 from '../src/media/Furniture/Final Images/Dining Tables-/Double Octagonal Metal Base Dining Table/FUR80.jpg'
import FUR81 from '../src/media/Furniture/Final Images/Dining Tables-/Double Octagonal Metal Base Dining Table/FUR81.jpg'
import FUR82 from '../src/media/Furniture/Final Images/Dining Tables-/Double Semi-Circlular Metal Base Dining Table/FUR82.jpg' ; 
import FUR83 from '../src/media/Furniture/Final Images/Dining Tables-/Double Semi-Circlular Metal Base Dining Table/FUR83.jpg' ; 
import FUR84 from '../src/media/Furniture/Final Images/Dining Tables-/H-Shaped Metal Base Dining Table/FUR84.jpg' ; 
import FUR85 from '../src/media/Furniture/Final Images/Dining Tables-/H-Shaped Metal Base Dining Table/FUR85.jpg' ; 
import FUR86 from '../src/media/Furniture/Final Images/Dining Tables-/Inverted Double Side Arrow Metal Base Dining Table/FUR86.jpg'
import FUR87 from '../src/media/Furniture/Final Images/Dining Tables-/Inverted Double Side Arrow Metal Base Dining Table/FUR87.jpg'
import FUR88 from '../src/media/Furniture/Final Images/Dining Tables-/Rectangular Bar Metal Base Dining Table/FUR88.jpg'
import FUR89 from '../src/media/Furniture/Final Images/Dining Tables-/Rectangular Bar Metal Base Dining Table/FUR89.jpg'
import FUR90 from '../src/media/Furniture/Final Images/Lounge - Accent chairs-/Blue Accent Chair/FUR90.jpg'
import FUR91 from '../src/media/Furniture/Final Images/Lounge - Accent chairs-/Blue Accent Chair/FUR91.png';
import FUR92 from '../src/media/Furniture/Final Images/Lounge - Accent chairs-/Dark Green Accent Chair/FUR92.jpg';
import FUR93 from '../src/media/Furniture/Final Images/Lounge - Accent chairs-/Dark Green Accent Chair/FUR93.jpg';
import FUR94 from '../src/media/Furniture/Final Images/Lounge - Accent chairs-/Grey Accent Chair/FUR94.jpg';
import FUR95 from '../src/media/Furniture/Final Images/Lounge - Accent chairs-/Grey Accent Chair/FUR95.jpg';
import FUR96 from '../src/media/Furniture/Final Images/Lounge - Accent chairs-/Grey Accent Chair with Leg Rest/FUR96.jpg';
import FUR97 from '../src/media/Furniture/Final Images/Lounge - Accent chairs-/Grey Accent Chair with Leg Rest/FUR97.jpg';
import FUR98 from '../src/media/Furniture/Final Images/Lounge - Accent chairs-/Pink Accent Chair/FUR98.png';
import FUR99 from '../src/media/Furniture/Final Images/Lounge - Accent chairs-/Pink Accent Chair/FUR99.png';
import FUR100 from '../src/media/Furniture/Final Images/Side Tables-/Bone Inlay Side Tables-/1-Drawer Floral Pattern Bone Inlay Side Table/FUR100.jpg'
import FUR101 from '../src/media/Furniture/Final Images/Side Tables-/Bone Inlay Side Tables-/1-Drawer Floral Pattern Bone Inlay Side Table/FUR101.jpg'
import FUR102 from '../src/media/Furniture/Final Images/Side Tables-/Bone Inlay Side Tables-/Black & White Geometric Pattern Bone Inlay Side Table/FUR102.jpg'; 
import FUR103 from '../src/media/Furniture/Final Images/Side Tables-/Bone Inlay Side Tables-/Black & White Geometric Pattern Bone Inlay Side Table/FUR103.jpg'; 
import FUR104 from '../src/media/Furniture/Final Images/Side Tables-/Bone Inlay Side Tables-/Cylindrical Three Sided Pattern Bone Inlay Side Table/FUR104.jpg'; 
import FUR105 from '../src/media/Furniture/Final Images/Side Tables-/Bone Inlay Side Tables-/Cylindrical Three Sided Pattern Bone Inlay Side Table/FUR105.jpg'; 
import FUR106 from '../src/media/Furniture/Final Images/Side Tables-/Bone Inlay Side Tables-/Hexagonal Bone Inlay Side Table/Hexagonal Black & White Bone Inlay Side Table/FUR106.png';
import FUR107 from '../src/media/Furniture/Final Images/Side Tables-/Bone Inlay Side Tables-/Hexagonal Bone Inlay Side Table/Hexagonal Black & White Bone Inlay Side Table/FUR107.jpg' ; 
import FUR108 from '../src/media/Furniture/Final Images/Side Tables-/Bone Inlay Side Tables-/Hexagonal Bone Inlay Side Table/Hexagonal Black & White Zig-Zag Pattern Bone Inlay Side Table/FUR108.jpg'
import FUR109 from '../src/media/Furniture/Final Images/Side Tables-/Bone Inlay Side Tables-/Small Drawer Bone Inlay Side Table/2-Drawer Circular Star Design Bone Inlay Side Table/FUR109.jpg' ; 
import FUR110 from '../src/media/Furniture/Final Images/Side Tables-/Bone Inlay Side Tables-/Small Drawer Bone Inlay Side Table/2-Drawer Circular Star Design Bone Inlay Side Table/FUR110.jpg' ; 
import FUR111 from '../src/media/Furniture/Final Images/Side Tables-/Bone Inlay Side Tables-/Small Drawer Bone Inlay Side Table/3-Drawer Floral Print Bone Inlay Side Table/FUR111.jpg' ; 
import FUR112 from '../src/media/Furniture/Final Images/Side Tables-/Bone Inlay Side Tables-/Small Drawer Bone Inlay Side Table/3-Drawer Floral Print Bone Inlay Side Table/FUR112.jpg' ; 
import FUR115 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/C-Shaped Metal Base Side Table/C-Shaped Metal Base Side Table - Black/FUR115.jpg'
import FUR116 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/C-Shaped Metal Base Side Table/C-Shaped Metal Base Side Table - Black/FUR116.jpg'
import FUR117 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/C-Shaped Metal Base Side Table/C-Shaped Metal Base Side Table - Gold/FUR117.jpg'
import FUR118 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/C-Shaped Metal Base Side Table/C-Shaped Metal Base Side Table - Gold/FUR118.jpg'
import FUR113 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Cross Base Metal Side Table/FUR113.jpg'
import FUR114 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Cross Base Metal Side Table/FUR114.jpg'
import FUR119 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Cube Base Metal Side Table/FUR119.jpg'
import FUR120 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Cube Base Metal Side Table/FUR120.jpg'
import FUR121 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Curved Metal Legs Side Table/FUR121.jpg'
import FUR122 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Curved Metal Legs Side Table/FUR122.jpg'
import FUR123 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Cylindrical Cage Base Side Table/FUR123.jpg'
import FUR124 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Cylindrical Cage Base Side Table/FUR124.jpg'
import FUR137 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Triangular Base with Three Metal Legs Side Table/FUR137.jpg' ; 
import FUR138 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Triangular Base with Three Metal Legs Side Table/FUR138.jpg' ; 
import FUR125 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Nesting Side Table/Set of 2 Nesting Side Table with Black Marble - Gold Finish/FUR125.jpg'
import FUR126 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Nesting Side Table/Set of 2 Nesting Side Table with Black Marble - Gold Finish/FUR126.jpg'
import FUR127 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Nesting Side Table/Set of 2 Nesting Side Table with White Marble - Gold Finish/FUR127.jpg'
import FUR128 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Nesting Side Table/Set of 2 Nesting Side Table with White Marble - Gold Finish/FUR128.jpg'
import  FUR129 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Nesting Side Table/Set of 3 Large Nesting Side Table with Black Marble - Gold Finish/FUR129.jpg'
import FUR130 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Nesting Side Table/Set of 3 Large Nesting Side Table with Black Marble - Gold Finish/FUR130.jpg'
import FUR131 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Nesting Side Table/Set of 3 Large Nesting Side Table with White Marble - Gold Finish/FUR131.jpg'
import FUR132 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Nesting Side Table/Set of 3 Large Nesting Side Table with White Marble - Gold Finish/FUR132.jpg'
import FUR133 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Nesting Side Table/Set of 3 Nesting Side Table - Silver Finish/FUR133.jpg'
import FUR134 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Nesting Side Table/Set of 3 Nesting Side Table - Silver Finish/FUR134.jpg'
import FUR135 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Nesting Side Table/Set of 3 Small Nesting Table - Gold Finish/FUR135.jpg' ; 
import FUR136 from '../src/media/Furniture/Final Images/Side Tables-/Metal Side Tables/Nesting Side Table/Set of 3 Small Nesting Table - Gold Finish/FUR136.jpg' ; 
import FUR139 from '../src/media/Furniture/Final Images/Sofas-/Blue 3-Seater Sofa/FUR139.jpg'
import FUR140 from '../src/media/Furniture/Final Images/Sofas-/Blue 3-Seater Sofa/FUR140.jpg'
import FUR141 from '../src/media/Furniture/Final Images/Sofas-/Brown 4-Seater Sofa/FUR141.jpg'
import FUR142 from '../src/media/Furniture/Final Images/Sofas-/Brown 4-Seater Sofa/FUR142.jpg'
import FUR143 from '../src/media/Furniture/Final Images/Sofas-/Green 3-Seater Sofa with Leg Rest/FUR143.jpg'
import FUR144 from '../src/media/Furniture/Final Images/Sofas-/Green 3-Seater Sofa with Leg Rest/FUR144.jpg'
import FUR145 from '../src/media/Furniture/Final Images/Sofas-/Green 6-Seater Sofa Set/FUR145.jpg'
import FUR146 from '../src/media/Furniture/Final Images/Sofas-/Green 6-Seater Sofa Set/FUR146.jpg'
import FUR147 from '../src/media/Furniture/Final Images/Sofas-/Grey 6-Seater Sofa/FUR147.jpg'
import FUR148 from '../src/media/Furniture/Final Images/Sofas-/Grey 6-Seater Sofa/FUR148.jpg';
import FUR149 from '../src/media/Furniture/Final Images/Center Tables-/nesting table set of 2/FUR149.PNG' ;
import FUR150 from '../src/media/Furniture/Final Images/Center Tables-/Nesting table set of 2 with 2 drawers/FUR150.PNG' ; 
import FUR151 from '../src/media/Furniture/Final Images/Center Tables-/Nesting table set of 2 with 2 drawers/FUR151.PNG' ; 
import FUR152 from '../src/media/Furniture/Final Images/Center Tables-/PVD center table/FUR152.jpeg' ; 
import FUR153 from '../src/media/Furniture/Final Images/Center Tables-/round center table/FUR153.jpg';
import FUR154 from '../src/media/Furniture/Final Images/Center Tables-/round center table/FUR154.jpg';
import FUR155 from '../src/media/Furniture/Final Images/Center Tables-/Twist Round Coffee Table with Marble Top/FUR155.jpg' ; 
import FUR156 from '../src/media/Furniture/Final Images/Center Tables-/Twist Round Coffee Table with Marble Top/FUR156.jpg' ; 
import FUR157 from '../src/media/Furniture/Final Images/Console Tables-/Contemporary Golden Base Console Table/FUR157.PNG' ; 
import FUR158 from '../src/media/Furniture/Final Images/Console Tables-/Contemporary Golden Base Console Table/FUR158.PNG' ; 
import FUR159 from '../src/media/Furniture/Final Images/Console Tables-/Honey comb Base Design Console Table/FUR159.PNG' ; 
import FUR160 from '../src/media/Furniture/Final Images/Console Tables-/Modern Console Table with Glass/FUR160.PNG' ; 
import FUR161 from '../src/media/Furniture/Final Images/Console Tables-/Modern Console Table with Glass/FUR161.PNG' ; 
import FUR162 from '../src/media/Furniture/Final Images/Console Tables-/Ring Design Console Table/FUR162.PNG' ; 
import FUR163 from '../src/media/Furniture/Final Images/Console Tables-/Ring Design Console Table/FUR163.PNG' ; 
import FUR164 from '../src/media/Furniture/Final Images/Console Tables-/Three Ovals Base Design Console Table/FUR164.PNG' ;
import FUR165 from '../src/media/Furniture/Final Images/Console Tables-/Three Ovals Base Design Console Table/FUR165.PNG' ;
import FUR166 from '../src/media/Furniture/Final Images/Side Tables-/Marble side table set of 2/FUR166.PNG' ; 
import FUR167 from '../src/media/Furniture/Final Images/Side Tables-/Marble side table set of 2/FUR167.PNG' ; 
import FUR168 from '../src/media/Furniture/Final Images/Side Tables-/round white marble side table/FUR168.PNG' ; 
import FUR169 from '../src/media/Furniture/Final Images/Side Tables-/round white marble side table/FUR169.PNG' ; 
import FUR170 from '../src/media/Furniture/Final Images/Side Tables-/Tulip Marble Side Table/FUR170.PNG';
import FUR171 from '../src/media/Furniture/Final Images/Side Tables-/Tulip Marble Side Table/FUR171.PNG';
import FUR172 from '../src/media/Furniture/Final Images/Side Tables-/Two-Teir Long Side Table/FUR172.PNG' ; 
import FUR173 from '../src/media/Furniture/Final Images/Side Tables-/Two-Teir Long Side Table/FUR173.PNG' ; 
import FUR174 from '../src/media/Furniture/Final Images/Side Tables-/Two-Teir Side Table Round/FUR174.PNG' ;
import FUR175 from '../src/media/Furniture/Final Images/Side Tables-/Two-Teir Side Table Round/FUR175.PNG' ;
import FUR176 from '../src/media/Furniture/Final Images/Sofas-/3 Seater Sofa in Grey Colour/FUR176.PNG' ;
import FUR177 from '../src/media/Furniture/Final Images/Sofas-/3 Seater Sofa in Grey Colour/FUR177.PNG' ; 
import FUR178 from '../src/media/Furniture/Final Images/Sofas-/5 Seater Fabric L Shape Sofa Set (Dark Blue)/FUR178.jpg' ; 
import FUR179 from '../src/media/Furniture/Final Images/Sofas-/5 Seater Fabric L Shape Sofa Set (Dark Blue)/FUR179.jpg' ; 
import FUR181 from '../src/media/Furniture/Final Images/Sofas-/5 Seater Fabric L Shape Sofa Set (Dark Grey)/FUR181.jpg' ;
import FUR182 from '../src/media/Furniture/Final Images/Sofas-/5 Seater Fabric L Shape Sofa Set (Dark Grey)/FUR182.jpg';
import FUR183 from '../src/media/Furniture/Final Images/Sofas-/5 Seater Fabric L Shape Sofa Set- (Dark Grey) LHS/FUR183.jpg'
import FUR184 from '../src/media/Furniture/Final Images/Sofas-/5 Seater Fabric L Shape Sofa Set- (Dark Grey) LHS/FUR184.jpg'
import FUR185 from '../src/media/Furniture/Final Images/Sofas-/Velvet 3 Seater Sofa In Green Colour/FUR185.PNG';
import FUR186 from '../src/media/Furniture/Final Images/Sofas-/Velvet 3 Seater Sofa In Green Colour/FUR186.PNG';

const  FurnitureData =[
    {
        _id: 1,
        image_1: FUR1,
        image_2: FUR2,
        name: "Circular Bar Trolley - Black Finish",
        Description: "",
        category: "Bar Trolley",
        
    
    },
    {
        _id: 2,
        image_1: FUR3,
        image_2: FUR4,
        name: "Circular Bar Trolley - Gold Finish",
        Description: "",
        category: "Bar Trolley",
        
    
    },
    {
        _id: 3,
        image_1: FUR5,
        image_2: FUR6,
        name: "Cylindrical Bar Trolley - Gold Finish",
        Description: "",
        category: "Bar Trolley",
        
    
    },
    {
        _id: 4,
        image_1: FUR7,
        image_2: FUR8,
        name: "Three layer Bar Trolley - Gold Finish",
        Description: "",
        category: "Bar Trolley",
        
    
    },
    {
        _id: 5,
        image_1: FUR9,
        image_2: FUR10,
        name: "Two layer Bar Trolley - Gold Finish",
        Description: "",
        category: "Bar Trolley",
        
    
    },
   
    {
        _id: 6,
        image_1: FUR11,
        image_2: FUR12,
        name: "7 Sided Black and White Bone Inlay Center Table",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 7,
        image_1: FUR13,
        image_2: FUR13,
        name: "8 Sided  Bone Inlay Center Table",
        Description: "",
        category: "Center Tables",
        
    
    },
  
    {
        _id: 9,
        image_1: FUR14,
        image_2: FUR15,
        name: "Black and White Stripes Bone Inlay Center Table",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 10,
        image_1: FUR16,
        image_2: FUR17,
        name: "Black and White Circular  Bone Inlay Center Table",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 11,
        image_1: FUR18,
        image_2: FUR19,
        name: "Blue and White Circular  Bone Inlay Center Table",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 11,
        image_1: FUR20,
        image_2: FUR20,
        name: "Geometric Design Circular Bone Inlay Center Table",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 12,
        image_1: FUR21,
        image_2: FUR22,
        name: "White Floral Design Circular Bone Inlay Center Table",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 13,
        image_1: FUR23,
        image_2: FUR24,
        name: "Oval Shaped Bone Inlay Center Table",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 14,
        image_1: FUR25,
        image_2: FUR26,
        name: "White Floral Print Rectangular Bone Inlay Center Table",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 15,
        image_1: FUR27,
        image_2: FUR28,
        name: "Iron Center Table - Circular Base",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 15,
        image_1: FUR30,
        image_2: FUR31,
        name: "Set of 2 Nesting Center Table-Black Marble Gold Finish ",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 16,
        image_1: FUR32,
        image_2: FUR33,
        name: "Set of 2 Nesting Center Table-Black Marble Gold PVD Finish ",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 17,
        image_1: FUR34,
        image_2: FUR35,
        name: "Set of 2 Nesting Center Table-White Marble Black Finish ",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 18,
        image_1: FUR36,
        image_2: FUR37,
        name: "Set of 2 Nesting Center Table-White Marble Gold PVD Finish ",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 19,
        image_1: FUR38,
        image_2: FUR39,
        name: "Set of 2 Simple Black Nesting Center Table-Black Finish",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 20,
        image_1: new1,
        image_2: new2,
        name: "Set of 2 Simple White Nesting Center Table-Black Finish",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 21,
        image_1: FUR40,
        image_2: FUR41,
        name: "Set of 2 Square Nesting Center Table-Black Finish",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 22,
        image_1: FUR42,
        image_2: FUR43,
        name: "Set of 2 Square Nesting Center Table-Gold Finish",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 23,
        image_1: FUR45,
        image_2: FUR46,
        name: "Set of 3 Nesting Center Table",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 24,
        image_1: FUR47,
        image_2: FUR48,
        name: "Set of 3 Square Nesting Center Table",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 25,
        image_1: FUR50,
        image_2: FUR51,
        name: "Multiple Sphere Base Stainless Steel Center Table",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 26,
        image_1: FUR52,
        image_2: FUR53,
        name: "Oval and Circular Set of 2 Center Table",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 26,
        image_1: FUR54,
        image_2: FUR54,
        name: "Rectangular Center Table with 2 Semi Circular Nesting Table",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 27,
        image_1: FUR54,
        image_2: FUR54,
        name: "Set of 2 Stainless Steel Center Table - Gold Finish ",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 28,
        image_1: FUR57,
        image_2: FUR58,
        name: "Set of 2 Stainless Steel Center Table - Silver Finish ",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 28,
        image_1: FUR59,
        image_2: FUR59,
        name: "Set of 2 Stainless Steel Center Table - Rose Gold Finish ",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 29,
        image_1: FUR60,
        image_2: FUR61,
        name: "Set of 3  Stainless Steel Nesting Center Table -  Gold Finish ",
        Description: "",
        category: "Center Tables",
        
    
    },
    {
        _id: 30,
        image_1: FUR62,
        image_2: FUR62,
        name: "Bone Inlay Console Table - Blue and White Floral Print Console Table ",
        Description: "",
        category: "Console Tables",
        
    
    },
    {
        _id: 31,
        image_1: FUR63,
        image_2: FUR64,
        name: "Four Drawer Bone Inlay Console  Table",
        Description: "",
        category: "Console Tables",
        
    
    },
    {
        _id: 32,
        image_1: FUR65,
        image_2: FUR66,
        name: "Three Drawer Bone Inlay Console  Table",
        Description: "",
        category: "Console Tables",
        
    
    },
    {
        _id: 33,
        image_1: FUR67,
        image_2: FUR68,
        name: "Three Drawer Bone Inlay Console  Table",
        Description: "",
        category: "Console Tables",
        
    
    },
    {
        _id: 34,
        image_1: FUR69,
        image_2: FUR70,
        name: "Two Drawer Bone Inlay Console  Table",
        Description: "",
        category: "Console Tables",
        
    
    },
    {
        _id: 34,
        image_1: FUR71,
        image_2: FUR72,
        name: "Circular Ring Design Metal Console Table",
        Description: "",
        category: "Console Tables",
        
    
    },
    {
        _id: 35,
        image_1: FUR73,
        image_2: FUR74,
        name: "Cross Base Console  Table",
        Description: "",
        category: "Console Tables",
        
    
    },
    {
        _id: 36,
        image_1: FUR75,
        image_2: FUR75,
        name: "Rectangular Console  Table",
        Description: "",
        category: "Console Tables",
        
    
    },
    {
        _id: 34,
        image_1: FUR76,
        image_2: FUR77,
        name: "U shaped Console  Table",
        Description: "",
        category: "Console Tables",
        
    
    },
    {
        _id: 35,
        image_1: FUR78,
        image_2: FUR79,
        name: "Curved Metal Legs with Center Ring Dining Table",
        Description: "",
        category: "Dining Tables",
        
    
    },
    {
        _id: 36,
        image_1: FUR80,
        image_2: FUR81,
        name: "Double Octagonal Metal Base Dining Table",
        Description: "",
        category: "Dining Tables",
        
    
    },
    {
        _id: 37,
        image_1: FUR82,
        image_2: FUR83,
        name: "Double Semi-Circular  Metal Base Dining Table",
        Description: "",
        category: "Dining Tables",
        
    
    },
    {
        _id: 38,
        image_1: FUR84,
        image_2: FUR85,
        name: "H-Shaped  Metal Base Dining Table",
        Description: "",
        category: "Dining Tables",
        
    
    },
    {
        _id: 39,
        image_1: FUR86,
        image_2: FUR87,
        name: "Inverted Double Side Arrow Metal Base Dining Table",
        Description: "",
        category: "Dining Tables",
        
    
    },
    {
        _id: 40,
        image_1: FUR88,
        image_2: FUR89,
        name: "Rectangular Bar  Metal Base Dining Table",
        Description: "",
        category: "Dining Tables",
        
    
    },
    {
        _id: 41,
        image_1: FUR90,
        image_2: FUR91,
        name: "Blue Accent Chair",
        Description: "",
        category: "Accent Chairs",
        
    
    },
    {
        _id: 42,
        image_1: FUR92,
        image_2: FUR93,
        name: "Dark Green Accent Chair",
        Description: "",
        category: "Accent Chairs",
        
    
    },
    {
        _id: 43,
        image_1: FUR94,
        image_2: FUR95,
        name: "Grey Accent Chair",
        Description: "",
        category: "Accent Chairs",
        
    
    },
    {
        _id: 44,
        image_1: FUR96,
        image_2: FUR97,
        name: "Grey Accent Chair with Leg Rest",
        Description: "",
        category: "Accent Chairs",
        
    
    },
    {
        _id: 45,
        image_1: FUR98,
        image_2: FUR99,
        name: "Pink Accent Chair ",
        Description: "",
        category: "Accent Chairs",
        
    
    },
 
    {
        "_id": 46,
        "image_1": FUR100,
        "image_2": FUR101,
        "name": "1 Drawer floral Pattern Bone Inlay Side Table",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 47,
        "image_1": FUR102,
        "image_2": FUR103,
        "name": "Black & White Geometric Pattern Bone Inlay Side Table",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 48,
        "image_1": FUR104,
        "image_2": FUR105,
        "name": "Cylindrical Three Sided Pattern Bone Inlay Side Table",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 49,
        "image_1": FUR106,
        "image_2": FUR107,
        "name": "Hexagonal Black & White Bone Inlay Side Table",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 50,
        "image_1": FUR108,
        "image_2": FUR108,
        "name": "Hexagonal Black & White Zig-Zag Pattern Bone Inlay Side Table",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 51,
        "image_1": FUR109,
        "image_2": FUR110,
        "name": "2-Drawer Circular Star Design Bone Inlay Side Table",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 52,
        "image_1": FUR111,
        "image_2": FUR112,
        "name": "3-Drawer Floral Print Bone Inlay Side Table",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 53,
        "image_1": FUR113,
        "image_2": FUR114,
        "name": "Cross Base Metal Side Table",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 54,
        "image_1": FUR115,
        "image_2": FUR116,
        "name": "C-Shaped Metal Base Side Table - Black",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 55,
        "image_1": FUR117,
        "image_2": FUR118,
        "name": "C-Shaped Metal Base Side Table - Gold",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 56,
        "image_1": FUR119,
        "image_2": FUR120,
        "name": "Cube Base Metal Side Table",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 57,
        "image_1": FUR121,
        "image_2": FUR122,
        "name": "Curved Metal Legs Side Table",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 58,
        "image_1": FUR123,
        "image_2": FUR124,
        "name": "Cylindrical Cage Base Side Table",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 59,
        "image_1": FUR125,
        "image_2": FUR126,
        "name": "Set of 2 Nesting Side Table with Black Marble - Gold Finish",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 60,
        "image_1": FUR127,
        "image_2": FUR128,
        "name": "Set of 2 Nesting Side Table with White Marble - Gold Finish",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 61,
        "image_1": FUR129,
        "image_2": FUR130,
        "name": "Set of 3 Large Nesting Side Table with Black Marble - Gold Finish",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 62,
        "image_1": FUR131,
        "image_2": FUR132,
        "name": "Set of 3 Large Nesting Side Table with White Marble - Gold Finish",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 63,
        "image_1": FUR133,
        "image_2": FUR134,
        "name": "Set of 3 Nesting Side Table - Silver Finish",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 64,
        "image_1": FUR135,
        "image_2": FUR136,
        "name": "Set of 3 Small Nesting Table - Gold Finish",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 65,
        "image_1": FUR137,
        "image_2": FUR138,
        "name": "Triangular Base with Three Metal Legs Side Table",
        "Description": "",
        "category": "Side Tables"
    },
   {
        "_id": 66,
        "image_1": FUR140,
        "image_2": FUR139,
        "name": "Blue 3-Seater Sofa",
        "Description": "",
        "category": "Sofas"
    },
    {
        "_id": 67,
        "image_1": FUR141,
        "image_2": FUR142,
        "name": "Brown 4-Seater Sofa",
        "Description": "",
        "category": "Sofas"
    },
    {
        "_id": 68,
        "image_1": FUR143,
        "image_2": FUR144,
        "name": "Green 3-Seater Sofa with Leg Rest",
        "Description": "",
        "category": "Sofas"
    },
    {
        "_id": 69,
        "image_1": FUR145,
        "image_2": FUR146,
        "name": "Green 6-Seater Sofa Set",
        "Description": "",
        "category": "Sofas"
    },
    {
        "_id": 70,
        "image_1": FUR147,
        "image_2": FUR148,
        "name": "Grey 6-Seater Sofa",
        "Description": "",
        "category": "Sofas"
    },
    {
        "_id": 71,
        "image_1": FUR149,
        "image_2": FUR150,
        "name": "Nesting Table Set of 2",
        "Description": "",
        "category": "Center Tables"
    },
    {
        "_id": 72,
        "image_1": FUR151,
        "image_2": FUR152,
        "name": "PVD Center Table",
        "Description": "",
        "category": "Center Tables"
    },
    {
        "_id": 73,
        "image_1": FUR153,
        "image_2": FUR154,
        "name": "Round Center Table",
        "Description": "",
        "category": "Center Tables"
    },
    {
        "_id": 74,
        "image_1": FUR155,
        "image_2": FUR156,
        "name": "Twist Round Coffee Table with Marble Top",
        "Description": "",
        "category": "Center Tables"
    },
    {
        "_id": 75,
        "image_1": FUR157,
        "image_2": FUR158,
        "name": "Contemporary Golden Base Console Table",
        "Description": "",
        "category": "Console Tables"
    },
    {
        "_id": 76,
        "image_1": FUR159,
        "image_2": FUR160,
        "name": "Modern Console Table with Glass",
        "Description": "",
        "category": "Console Tables"
    },
    {
        "_id": 77,
        "image_1": FUR161,
        "image_2": FUR162,
        "name": "Ring Design Console Table",
        "Description": "",
        "category": "Console Tables"
    },
    {
        "_id": 78,
        "image_1": FUR163,
        "image_2": FUR164,
        "name": "Three Ovals Base Design Console Table",
        "Description": "",
        "category": "Console Tables"
    },
    {
        "_id": 79,
        "image_1": FUR165,
        "image_2": FUR166,
        "name": "Marble Side Table Set of 2",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 80,
        "image_1": FUR167,
        "image_2": FUR168,
        "name": "Round White Marble Side Table",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 81,
        "image_1": FUR169,
        "image_2": FUR170,
        "name": "Tulip Marble Side Table",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 82,
        "image_1": FUR171,
        "image_2": FUR172,
        "name": "Two-Tier Long Side Table",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 83,
        "image_1": FUR173,
        "image_2": FUR174,
        "name": "Two-Tier Side Table Round",
        "Description": "",
        "category": "Side Tables"
    },
    {
        "_id": 84,
        "image_1": FUR175,
        "image_2": FUR176,
        "name": "3 Seater Sofa in Grey",
        "Description": "",
        "category": "Sofas"
    },
    {
        "_id": 85,
        "image_1": FUR177,
        "image_2": FUR178,
        "name": "5 Seater L Shape Sofa Set (Dark Blue)",
        "Description": "",
        "category": "Sofas"
    },
    {
        "_id": 86,
        "image_1": FUR185,
        "image_2": FUR186,
        "name": "Velvet 3 Seater Sofa in Green Colour",
        "Description": "",
        "category": "Sofas"
    },
    {
        "_id": 87,
        "image_1": FUR181,
        "image_2": FUR182,
        "name": "5 Seater L Shape Sofa Set (Dark Grey)",
        "Description": "",
        "category": "Sofas"
    },
    {
        "_id": 88,
        "image_1": FUR183,
        "image_2": FUR184,
        "name": "5 Seater L Shape Sofa Set (Dark Grey) LHS",
        "Description": "",
        "category": "Sofas"
    },
];




export default FurnitureData ; 