import React, { useState } from 'react';
import HomeNavbar from '../components/HomeNavbar';
import { useForm, ValidationError } from '@formspree/react';
import { Link } from 'react-router-dom';
import Logo from '../media/LandingPage/Logo.png';

function BulkOrder() {
  const [state, handleSubmit] = useForm("xkndlnjr");
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  if (state.succeeded) {
    return (
      <div className={`modal ${showModal ? "show" : ""}`}>
        <div className="modal-content flex flex-col items-center justify-center">
          <p className='text-5xl font-bold mt-12'>Thanks for reaching out to The Raavya!</p>
          <Link to='/'><img src={Logo} alt='Contact-img' className='mt-36 mr-48'></img></Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <HomeNavbar />
      <h1 className='text-center text-xl font-semibold m-2'>BULK ORDER</h1>
      <hr />
      <form className='BulkOrder' onSubmit={(e) => { e.preventDefault(); handleSubmit(e); openModal(); }}>
        <div className='bulkform-1'>
          <div className='form1-left'>
            <input placeholder='Full Name' type="text" name="name" />
            <input placeholder='Street Address' type="text" name="streetAddress" />
            <input placeholder='City' type="text" name="city" />
            <input placeholder='Postal/Zip Code' type="number" name="postalCode" />
          </div>
          <div className='form2-right'>
            <input placeholder='Email' type="text" name="email" />
            <input placeholder='Address Line 2' type="text" name="addressLine2" />
            <input placeholder='State / Province / Region' type="text" name="stateProvinceRegion" />
            <input placeholder='Country / Region' type="text" name="countryRegion" />
          </div>
        </div>
        <div className='bulkform-2'>
          <input placeholder="Mobile Number" type="tel" pattern="[0-9]{10}" maxLength="10" required name="mobileNumber" />
          <textarea placeholder='Request Information' type='text' name="comments"></textarea>
        </div>
        <div className="flex items-center justify-center mt-7 mb-2">
          <button className="bg-blue-500 text-white font-bold py-2 px-4 rounded border-2 border-blue-500 hover:bg-blue-700 hover:border-blue-700 transition duration-300 w-48" type="submit">
            Submit
          </button>
        </div>
      </form>
    </>
  );
}

export default BulkOrder;
