import React, { useEffect, useState } from 'react';
import Image1 from '../media/LandingPage/1.png';
import Image2 from '../media/LandingPage/2.png';
import Image3 from '../media/LandingPage/3.jpg';
import Image4 from '../media/LandingPage/4.jpg';
import Logo from '../media/LandingPage/Logo.png';
import s1 from '../media/LandingPage/whatsapp.png';
import s2 from '../media/LandingPage/facebook.png';
import s3 from '../media/LandingPage/instagram.png';
import s4 from '../media/LandingPage/amazon.png';
import { Link } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';

function Home() {
  const images = [Image1, Image2, Image3, Image4];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(false);
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className='Home-Container'>
      <div className='navbar'>
        <div className="LogoDiv">
          <img src={Logo} alt="logo"></img>
        </div>
        <div className='nav-part1'>
          <ul>
            <Link to="/about"><li>About</li></Link>   
            <Link to="/products"><li>Products</li></Link>   
            <Link to="/bulkorder"><li>Bulk Order</li></Link>  
            <Link to="/contact"><li>Contact Us</li></Link>
            <Link to="/cart"><li>Cart</li></Link>
          </ul>
        </div>
        <div className="socials">
          <ReactWhatsapp number="917701853700" message="Hi !"><img src={s1}></img></ReactWhatsapp>
          <a href="https://www.facebook.com/theraavya/" target='_blank'><img src={s2}></img></a>
          <a href="https://www.instagram.com/theraavya/" target='_blank'><img src={s3}></img></a>
          <a href="https://www.amazon.in/stores/page/31BEBC02-A059-4270-A214-BBD72A454047" target='_blank'><img src={s4}></img></a>
        </div>
        <div className="address ">
          <p className='font-bold text-black'>Home Address :</p>
          <p>552-Mandakini Enclave <br />Alaknanda,New Delhi-110019</p>
          <br />
          <p className='font-bold text-black'>Office Address :</p>
          <p>C-23 Sector 6  <br />Noida,UP-201301</p>
        </div>
        
      </div>
      <div
        className={`Image-Slider ${fade ? 'fade' : ''}`}
        style={{
          backgroundImage: `url(${images[currentImageIndex]})`,
          backgroundPosition: 'center center'
        }}
      ></div>
    </div>
  );
}

export default Home;
