import React from 'react'
import Home from '../pages/Home' ; 
import {Routes,Route} from 'react-router-dom' ; 
import About from '../pages/About' ; 
import Products from '../pages/Products' ;
import Contact from '../pages/ContactUs' ; 
import BulkOrder from '../pages/BulkOrder' ; 
import HomeDecor from '../pages/HomeDecor';
import Furniture from '../pages/Furniture';
import Cart from '../pages/Cart';

function AllRoutes() {
  return (
    <>
    <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/products' element={<Products/>}/>
        <Route path='/bulkorder' element={<BulkOrder/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/HomeDecor' element={<HomeDecor/>}/>
        <Route path='/furniture' element={<Furniture/>}/>
        <Route path='/cart' element={<Cart/>}/>
    </Routes>
    </>
  )
}

export default AllRoutes