import React, { useEffect } from 'react';
import HomeNavbar from '../components/HomeNavbar';
import { useSelector, useDispatch } from 'react-redux';
import { MdDelete } from "react-icons/md";
import { clearCart, removeItem } from '../Features/cartSlice';
import { useForm } from '@formspree/react';

function CartItem({ item, index, handleRemoveItem }) {
    return (
        <div key={item.id} className='bg-white shadow-md rounded-lg p-4'>
            <div className='flex justify-between items-center'>
                <div className='flex items-center'>
                    <span className='text-2xl font-bold mr-4'>{index + 1}.</span>
                    <img src={item.image_1} alt={item.name} className='w-20 h-20 object-cover' />
                    <div className='ml-4'>
                        <h2 className='text-xl font-bold'>{item.name}</h2>
                        <p className='text-gray-600'>{item.category}</p>
                    </div>
                </div>
                <button className='text-red-600 hover:text-red-800' onClick={() => handleRemoveItem(item.id)}>
                    <MdDelete size={24} />
                </button>
            </div>
        </div>
    );
}

function Cart() {
    const { cart } = useSelector((state) => state.allcart);
    const dispatch = useDispatch();
    const [state, handleSubmit] = useForm("meqybpej");

    const handleRemoveItem = (itemId) => {
        dispatch(removeItem(itemId));
    };

    useEffect(() => {
        if (state.succeeded) {
            alert("Enquiry Submitted Successfully");
            dispatch(clearCart());
        }
    }, [state.succeeded, dispatch]);

    return (
        <>
            <HomeNavbar />
            <div className='Cart-Section p-8'>
                {cart.length === 0 ? (
                    <h1 className='text-3xl font-bold'>Your Cart is Empty...</h1>
                ) : (
                    <>
                        <div className='grid grid-cols-1 gap-4'>
                            {cart.map((item, index) => (
                                <CartItem key={item.id} item={item} index={index} handleRemoveItem={handleRemoveItem} />
                            ))}
                        </div>
                        <form className='flex justify-end mt-4' onSubmit={handleSubmit}>
                            {cart.map((item, index) => (
                                <React.Fragment key={index}>
                                    <input type="hidden" name={`productName[${index}]`} value={item.name} />
                                    <input type="hidden" name={`productCategory[${index}]`} value={item.category} />
                                </React.Fragment>
                            ))}
                            <button type='submit' className='bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700'>
                                SUBMIT
                            </button>
                        </form>
                    </>
                )}
            </div>
        </>
    );
}

export default Cart;
