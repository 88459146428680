import React from 'react'
import Logo2 from '../media/Navbar.png' ; 
import { BsCart } from "react-icons/bs";
import {Link} from 'react-router-dom' ; 
import { useSelector } from 'react-redux';

function HomeNavbar() {
  const { cart } = useSelector((state) => state.allcart);
  return (
   <>
   <nav className='InnerNavBar'>
        <ul>
           <Link to="/"><li><img src={Logo2} alt="logo2"></img></li> </Link>
            <li><ul className='navpart1 py-5 ml-5'>
            <Link to="/"><li>Home</li></Link>  
            <Link to="/about"><li>About</li></Link>   
         <Link to="/products"><li>Products</li></Link>   
          <Link to="/bulkorder"><li>Bulk Order</li></Link>  
            <Link to="/contact"><li>Contact Us</li></Link>
              <Link to='/cart'><li><span className='flex'><BsCart  className='w-5 h-5'/>({cart.length})</span></li></Link>  
                </ul></li>
                <li>
                         <li></li>

                </li>

        </ul>

   </nav>

   
   </>
  )
}

export default HomeNavbar