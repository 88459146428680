// src/App.js
import React, { useEffect } from 'react';
import AllRoutes from './Routes/AllRoutes';
import { initializeGTM } from './gtm';

function App() {
  useEffect(() => {
    // Initialize Google Tag Manager
    const GTM_ID = 'G-YW82GT89N1'; // Replace with your GTM ID
    initializeGTM(GTM_ID);

    // Initialize Google Analytics
    const GA_ID = 'AW-16498731026'; // Replace with your Google Analytics ID
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', GA_ID);
    };
  }, []);

  return (
    <>
      <AllRoutes />
    </>
  );
}

export default App;
