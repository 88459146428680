import { createSlice } from "@reduxjs/toolkit";
import HomeDecor from '../HomeDecor' ; 
import FurnitureData from "../Furniture";




const initialState = {
    cart:[] ,
    items:HomeDecor,FurnitureData,
    totalQuantity:0,
} ; 


export const cartSlice = createSlice({
    name:'cart' , 
    initialState,
    reducers:{
        addToCart:(state,action)=>{
            const {id} = action.payload ; 
            const existingItemIndex = state.cart.findIndex((item)=>item.id===id)
            if(existingItemIndex >= 0){
             return{
                ...state,
                cart:[...state.cart,{...action.payload}]
             }
           
            }
            else if(existingItemIndex===1){
                return state

            }
            else{
                return{
                    ...state , 
                    cart:[...state.cart,{...action.payload}]
                }
            }

        },
        removeItem:(state,action)=>{
            const index = state.cart.findIndex((item)=>item.id===action.payload) ; 
            if(index!==-1){
                state.cart.splice(index,1) ; 
            }
        },
        clearCart:(state)=>{
            state.cart=[]
        }
      
    }
})


export const {
    addToCart,
    removeItem,
    clearCart
   
} = cartSlice.actions ; 


export default cartSlice.reducer ; 