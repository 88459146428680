import React from 'react'
import HomeNavbar from '../components/HomeNavbar'
import AboutImg from '../media/about.png' ; 

function About() {
  return (
    <>
    <HomeNavbar/>
    <div className='About-container'>

         <img src={AboutImg} alt="alt-image"></img>
      
      <div className='about-text'>
       <p className='text-2xl  text-justiy'>We started <b>THE RAAVYA </b>with a vision to make a million homes beautiful.

When our customers shared photographs of their homes, we saw they were remarkably distinct. The same table, the same chair was used differently by different people. This told us something. People want their homes to be unique. We also realized that people are a lot happier creating things than they

are buying things.</p>
<br></br>
       <p className='text-2xl  text-justify'>Our mission is to make high-quality, customized sustainable furniture and home decor accessible to all. vertical integration, we reduce costs and pass the savings on, making sustainable living more affordable and inclusive.</p>
       <br></br>
       <p className=' text-xl font-bold'>-Raghav Khanna (Founder,TheRaavya)</p>
      </div>


    </div>
    </>
  )
}

export default About