import React, { useState } from 'react';
import HomeNavbar from '../components/HomeNavbar';
import FurnitureData from '../Furniture';
import { IoCaretForwardSharp, IoCaretBackSharp } from "react-icons/io5";
import { AiFillAmazonCircle } from "react-icons/ai";
import wpicon from '../media/whatsappicon.png';
import ReactWhatsapp from 'react-whatsapp';
import { GrPowerReset } from "react-icons/gr";
import { useDispatch } from 'react-redux';
import { addToCart } from '../Features/cartSlice';
import { useNavigate } from 'react-router-dom';

function HomeDecor() {
  const uniqueCategories = new Set(FurnitureData.map(item => item.category));
  const [filter, setFilter] = useState(null);
  const filteredData = filter ? FurnitureData.filter((item) => item.category === filter) : FurnitureData;

  return (
    <>
      <HomeNavbar />
      <div className='relative'>
        <div className='HomeDecor-Section'>
          <div className='Decor-filter  '>
            <h2 className='text-3xl font-bold mt-24'>Categories</h2>
            <button className='bg-white text-black p-2 shadow-xl border font-semibold rounded-full mr-2 hover:bg-black hover:text-white transition duration-300 my-5' onClick={() => setFilter(null)}><GrPowerReset /></button>
            <ul className='mt-12'>
              {[...uniqueCategories].map((category, index) => (
                <li key={index} className='text-xl cursor-pointer hover:text-blue-500' onClick={() => setFilter(category)}>
                  {category}
                </li>
              ))}
            </ul>
          </div>
          <div className='Decor-products grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full shadow-xl p-8 ml-12 mt-16'>
            {filteredData.map((item, index) => (
              <DecorCard key={index} item={item} />
            ))}
          </div>
        </div>
        <div className="fixed bottom-4 right-4">
          <ReactWhatsapp number="9177018 53700" message="Hi !"><img src={wpicon} alt='whatsapp-icon' className='w-12 h-12'></img></ReactWhatsapp>
        </div>
      </div>
    </>
  );
}

function DecorCard({ item }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const images = [item.image_2, item.image_1];
  const dispatch = useDispatch();
  const navigate = useNavigate() ; 

  const handleNextImage = () => setCurrentImage((prev) => (prev + 1) % images.length);
  const handlePrevImage = () => setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
  const handleAddtoCart = () => {
    dispatch(addToCart(item));
  };

  const openPreview = () => setIsPreviewOpen(true);
  const closePreview = () => setIsPreviewOpen(false);
  const handleNavigate =()=>{
    navigate('/bulkorder')
  }

  return (
    <div className='bg-white  overflow-hidden w-96 h-auto'>
      <div className='relative h-80 cursor-pointer' onClick={openPreview}>
        <img src={images[currentImage]} alt={item.name} className='w-full h-full object-fit rounded-t-lg   transition duration-500 ease-in-out transform hover:scale-105' />
        <div className='absolute inset-y-0 left-0 flex items-center pl-2'>
          <button
            className='bg-white text-gray-800 p-2 rounded-full shadow-md hover:bg-gray-200 transition duration-300'
            onClick={handlePrevImage}
          >
            <IoCaretBackSharp size={24} />
          </button>
        </div>
        <div className='absolute inset-y-0 right-0 flex items-center pr-2'>
          <button
            className='bg-white text-gray-800 p-2 rounded-full shadow-md hover:bg-gray-200 transition duration-300'
            onClick={handleNextImage}
          >
            <IoCaretForwardSharp size={24} />
          </button>
        </div>
      </div>
      <div className='m-2'>
        <h2 className='text-xl font-semibold mb-2 text-center'>{item.name}</h2>
        <p className='text-gray-700 mb-4'>{item.Description}</p>
        <div className='flex justify-center gap-2'>
          <button className='bg-gray-800 text-white py-2 px-4 rounded-full mr-2 hover:bg-gray-600 transition duration-300' onClick={handleAddtoCart}>
            Ask for Price
          </button>
          <button className='bg-gray-800 text-white py-2 px-4 rounded-full hover:bg-gray-600 transition duration-300' onClick={handleNavigate}>
            Bulk Order
          </button>
          <a href={item.amazonLink} target="_blank" rel="noopener noreferrer">
          <ReactWhatsapp 
      number="917701853700" 
      message={`Hi! You  have an enquiry for ${item.name}`}
    >
      <button className='flex flex-col items-center bg-gray-800 text-white py-2 px-4 rounded-full hover:bg-gray-600 transition duration-300'>
         Buy It On 
        <img src={wpicon} alt='whatsapp-icon' className='w-5 h-5' />
      </button>
    </ReactWhatsapp>
          </a>
        </div>
      </div>
      {isPreviewOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75" onClick={closePreview}>
          <div className="max-w-3xl max-h-full">
            <img src={images[currentImage]} alt={item.name} className="w-full h-full object-contain" />
          </div>
        </div>
      )}
    </div>
  );
}

export default HomeDecor;