import React, { useState } from 'react';
import Logo from '../media/LandingPage/Logo.png'; 
import HomeNavbar from '../components/HomeNavbar';
import { useForm, ValidationError } from '@formspree/react';
import { Link } from 'react-router-dom';


function ContactUs() {
  const [state, handleSubmit] = useForm("mdoqroab");
  const [showModal, setShowModal] = useState(false);

 

  const openModal = () => {
    setShowModal(true);
  };

  if (state.succeeded) {
    return (
      <div className={`modal ${showModal ? "show" : ""}`}>
        <div className="modal-content  flex flex-col items-center justify-center">
         
          <p className='text-5xl font-bold mt-12 '>Thanks for reaching out to The Raavya! </p>
          <Link to='/'><img src={Logo} alt='Contact-img' className='mt-36 mr-48'></img></Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <HomeNavbar />
      <div className="ContactUs">
        <div className="Contact-img  ">
          <img src={Logo} alt='Contact-img'></img>
          <p className='text-xl ml-36 font-bold'>+91 7701853700 ,9873716778</p>
          <p className='text-xl ml-36 font-bold'>raavya2629@gmail.com , raghav@theraavya.in</p>
           
          
          <p className='ml-36 mt-7  text-xl'>  <p className='font-semibold'>Home Address :</p> 552-Mandakini Enclave <br />Alaknanda,New Delhi-110019</p>
          <br />
          <p className='ml-36 text-xl'><p className='font-semibold'>Office Address :</p>C-23 Sector 6  <br />Noida,UP-201301</p>
      
        </div>
        <form className="Contact-form" onSubmit={(e) => {handleSubmit(e); openModal();}}>
          <h1 className='font-bold text-3xl'>Get In Touch</h1>
          <div className=' line border border-gray w-80 m-5'></div>
          
          <div className='form-input'>
            <input 
              type='text' 
              placeholder='Enter your Name' 
              name="name"
              required
            />
          </div>
          <ValidationError 
            prefix="Name" 
            field="name"
            errors={state.errors}
          />
          
          <div className='form-input'>
            <input 
              type='email' 
              placeholder='Enter Your Email' 
              name='email'
              required
            />
          </div>
          <ValidationError 
            prefix="Email" 
            field="email"
            errors={state.errors}
          />
          
          <div className='form-input'>
            <textarea 
              type='text' 
              placeholder='Enter Your Message' 
              name='message'
              required
            />
          </div>
          <ValidationError 
            prefix="Message" 
            field="message"
            errors={state.errors}
          />
          
          <button 
            className='submit-button' 
            type="submit" 
            disabled={state.submitting}
          >
            SUBMIT
          </button>
        </form>
      </div>
    </>
  );
}

export default ContactUs;
