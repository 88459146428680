import React, { useState, useCallback } from 'react';
import HomeDecor from '../media/hd1.png';
import Furniture from '../media/fur1.png';
import { useNavigate } from 'react-router-dom';

function Products() {
  const [showHomeDecorButton, setShowHomeDecorButton] = useState(false);
  const [showFurnitureButton, setShowFurnitureButton] = useState(false);
  const navigate = useNavigate();

  const handleHomeDecor = useCallback(() => {
    navigate('/HomeDecor');
  }, [navigate]);

  const handleFurniture = useCallback(() => {
    navigate('/furniture');
  }, [navigate]);

  const handleMouseEnterHomeDecor = useCallback(() => {
    setShowHomeDecorButton(true);
  }, []);

  const handleMouseLeaveHomeDecor = useCallback(() => {
    setShowHomeDecorButton(false);
  }, []);

  const handleMouseEnterFurniture = useCallback(() => {
    setShowFurnitureButton(true);
  }, []);

  const handleMouseLeaveFurniture = useCallback(() => {
    setShowFurnitureButton(false);
  }, []);

  return (
    <>
      <div className='Product-Screen w-screen h-screen flex flex-col md:flex-row overflow-hidden'>
        <div 
          className="relative w-full md:w-1/2 h-1/2 md:h-full" 
          onMouseEnter={handleMouseEnterHomeDecor} 
          onMouseLeave={handleMouseLeaveHomeDecor}
        >
          <img src={HomeDecor} alt="home-decor" className='w-full h-full object-fit' />
          {showHomeDecorButton && (
            <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
          )}
          {showHomeDecorButton && (
            <button 
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full p-2 text-white text-2xl md:text-5xl px-4 py-2"
              onClick={handleHomeDecor}
            >
              Home Decor
            </button>
          )}
        </div>
        <div 
          className="relative w-full md:w-1/2 h-1/2 md:h-full" 
          onMouseEnter={handleMouseEnterFurniture} 
          onMouseLeave={handleMouseLeaveFurniture}
        >
          <img src={Furniture} alt="furniture" className='w-full h-full object-cover' />
          {showFurnitureButton && (
            <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
          )}
          {showFurnitureButton && (
            <button 
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full p-2 text-white text-2xl md:text-5xl px-4 py-2"
              onClick={handleFurniture}
            >
              Furniture
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default Products;
