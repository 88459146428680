import DB1 from '../src/media/HomeDecor/candle stands/dark brown set of 2/DB1.jpg';
import DB2 from '../src/media/HomeDecor/candle stands/dark brown set of 2/DB2.jpg';
import GR1 from '../src/media/HomeDecor/candle stands/green set of 3/GR1.jpg';
import GR2 from '../src/media/HomeDecor/candle stands/green set of 3/GR2.jpg';
import GY1 from '../src/media/HomeDecor/candle stands/grey set of 3/GY1.jpg';
import GY2 from '../src/media/HomeDecor/candle stands/grey set of 3/GY2.jpg';
import NAT1 from '../src/media/HomeDecor/candle stands/Natural set of 3/NAT1.jpg';
import NAT2 from '../src/media/HomeDecor/candle stands/Natural set of 3/NAT2.jpg';
import OWLB1 from '../src/media/HomeDecor/candle stands/owl candle stands/brown/OWLB1.jpg';
import OWLB2 from '../src/media/HomeDecor/candle stands/owl candle stands/brown/OWLB2.jpg';
import OWLW1 from '../src/media/HomeDecor/candle stands/owl candle stands/white/OWLW1.jpg';
import OWLW2 from '../src/media/HomeDecor/candle stands/owl candle stands/white/OWLW2.jpg';
import SW1 from '../src/media/HomeDecor/candle stands/single white/SW1.jpg';
import SW2 from '../src/media/HomeDecor/candle stands/single white/SW2.jpg';
import WS1 from '../src/media/HomeDecor/candle stands/white set of 2/WS1.jpg';
import WS2 from '../src/media/HomeDecor/candle stands/white set of 2/WS2.jpg';
import WH1 from '../src/media/HomeDecor/candle stands/White set of 3/WH1.jpg';
import WH2 from '../src/media/HomeDecor/candle stands/White set of 3/WH2.jpg';
import WW1 from '../src/media/HomeDecor/candle stands/white square base/WW1.jpg';
import WW2 from '../src/media/HomeDecor/candle stands/white square base/WW2.jpg';
import BO1 from '../src/media/HomeDecor/cutlery/bottle opener/BO1.jpg';
import BO2 from '../src/media/HomeDecor/cutlery/bottle opener/BO2.jpg';
import CB1 from '../src/media/HomeDecor/cutlery/Cheese knives set of 4/brown set of 3/CB1.jpg';
import CB2 from '../src/media/HomeDecor/cutlery/Cheese knives set of 4/brown set of 3/CB2.jpg';
import G1 from '../src/media/HomeDecor/cutlery/Cheese knives set of 4/Gold/G1.jpg';
import G2 from '../src/media/HomeDecor/cutlery/Cheese knives set of 4/Gold/G2.jpg';
import GPV1 from '../src/media/HomeDecor/cutlery/Cheese knives set of 4/Gold PVD Set of 3/GPV1.jpg';
import GPV2 from '../src/media/HomeDecor/cutlery/Cheese knives set of 4/Gold PVD Set of 3/GPV2.jpg';
import GG1 from '../src/media/HomeDecor/cutlery/Cheese knives set of 4/Green/GG1.jpg';
import GG2 from '../src/media/HomeDecor/cutlery/Cheese knives set of 4/Green/GG2.jpg';
import RS1 from '../src/media/HomeDecor/cutlery/Cheese knives set of 4/Rose Gold set of 3/RS1.jpg';
import RS2 from '../src/media/HomeDecor/cutlery/Cheese knives set of 4/Rose Gold set of 3/RS2.jpg';
import BC1 from '../src/media/HomeDecor/cutlery/cutlery with round agate stone handle/blue/BC1.jpg';
import BC2 from '../src/media/HomeDecor/cutlery/cutlery with round agate stone handle/blue/BC2.jpg';
import LY1 from '../src/media/HomeDecor/cutlery/cutlery with round agate stone handle/lemon yellow/LY1.jpg';
import LY2 from '../src/media/HomeDecor/cutlery/cutlery with round agate stone handle/lemon yellow/LY2.jpg';
import W1 from '../src/media/HomeDecor/cutlery/cutlery with round agate stone handle/white/W1.jpg';
import W2 from '../src/media/HomeDecor/cutlery/cutlery with round agate stone handle/white/W2.jpg';
import EP1 from '../src/media/HomeDecor/cutlery/Electroplated set of 4/EP1.jpg';
import EP2 from '../src/media/HomeDecor/cutlery/Electroplated set of 4/EP2.jpg';
import NAV1 from '../src/media/HomeDecor/cutlery/Navy Blue Set of 4/NAV1.jpg';
import NAV2 from '../src/media/HomeDecor/cutlery/Navy Blue Set of 4/NAV2.jpg';
import O1 from '../src/media/HomeDecor/cutlery/Orange set of 4/O1.jpg';
import O2 from '../src/media/HomeDecor/cutlery/Orange set of 4/O2.jpg';
import F1 from '../src/media/HomeDecor/cutlery/serving cutlery/Gold Finish/F1.jpg';
import F2 from '../src/media/HomeDecor/cutlery/serving cutlery/Gold Finish/F2.jpg';
import P1 from '../src/media/HomeDecor/cutlery/serving cutlery/Rose Gold PVD/P1.jpg';
import P2 from '../src/media/HomeDecor/cutlery/serving cutlery/Rose Gold PVD/P2.jpg';
import WO1 from '../src/media/HomeDecor/cutlery/Wood and resin handles/green round/WO1.jpg';
import WO2 from '../src/media/HomeDecor/cutlery/Wood and resin handles/green round/WO2.jpg';
import OUN1 from '../src/media/HomeDecor/cutlery/Wood and resin handles/red round/OUN1.jpg';
import OUN2 from '../src/media/HomeDecor/cutlery/Wood and resin handles/red round/OUN2.jpg';
import NAP1 from '../src/media/HomeDecor/Napkin rings/blue/NAP1.jpg';
import NAP2 from '../src/media/HomeDecor/Napkin rings/blue/NAP2.jpg';
import NAG1 from '../src/media/HomeDecor/Napkin rings/green/NAG1.jpg';
import NAG2 from '../src/media/HomeDecor/Napkin rings/green/NAG2.jpg';
import NAW1 from '../src/media/HomeDecor/Napkin rings/white/NAW1.jpg';
import NAW2 from '../src/media/HomeDecor/Napkin rings/white/NAW2.jpg';
import PHO1 from '../src/media/HomeDecor/Photo Frames/Green/PHO1.jpg';
import PHO2 from '../src/media/HomeDecor/Photo Frames/Green/PHO2.jpg';
import PHW1 from '../src/media/HomeDecor/Photo Frames/Grey/PHW1.jpg';
import PHW2 from '../src/media/HomeDecor/Photo Frames/Grey/PHW2.jpg';
import PH1 from '../src/media/HomeDecor/Photo Frames/White/PH1.jpg';
import PH2 from '../src/media/HomeDecor/Photo Frames/White/PH2.jpg';
import PB1 from '../src/media/HomeDecor/Photo Frames/white with brown corners/PB1.jpg';
import PB2 from '../src/media/HomeDecor/Photo Frames/white with brown corners/PB2.jpg';
import PHS1 from '../src/media/HomeDecor/Photo Frames/white with brown stripes/PHS1.jpg';
import PHS2 from '../src/media/HomeDecor/Photo Frames/white with brown stripes/PHS2.jpg';
import SB1 from '../src/media/HomeDecor/Salad bowl/Blue & White geometric/SB1.jpg';
import SB2 from '../src/media/HomeDecor/Salad bowl/Blue & White geometric/SB2.jpg';
import SBE1 from '../src/media/HomeDecor/Salad bowl/Emerald Green leaves/SBE1.jpg';
import SBE2 from '../src/media/HomeDecor/Salad bowl/Emerald Green leaves/SBE2.jpg';
import SBG1 from '../src/media/HomeDecor/Salad bowl/Green paradise/SBG1.jpg';
import SBG2 from '../src/media/HomeDecor/Salad bowl/Green paradise/SBG2.jpg';
import SBZ1 from '../src/media/HomeDecor/Salad bowl/White with Blue & Green leaves/SBZ1.jpg';
import SBZ2 from '../src/media/HomeDecor/Salad bowl/White with Blue & Green leaves/SBZ2.jpg';
import TBG1 from '../src/media/HomeDecor/Tissue box/green/TBG1.jpg';
import TBG2 from '../src/media/HomeDecor/Tissue box/green/TBG2.jpg';
import TH1 from '../src/media/HomeDecor/Tissue box/grey/TH1.jpg' ;
import TH2 from '../src/media/HomeDecor/Tissue box/grey/TH2.jpg' ;
import TMP1 from '../src/media/HomeDecor/Tissue box/mother of pearl/TMP1.jpg';
import TMP2 from '../src/media/HomeDecor/Tissue box/mother of pearl/TMP2.jpg';
import TSH1 from '../src/media/HomeDecor/Tissue box/Square holder/TSH1.jpg';
import TSH2 from '../src/media/HomeDecor/Tissue box/Square holder/TSH2.jpg';
import TT1 from '../src/media/HomeDecor/Tissue box/white/TT1.jpg';
import TT2 from '../src/media/HomeDecor/Tissue box/white/TT2.jpg';
import BWPC1 from '../src/media/HomeDecor/Tray & Platters/Black & White Pyramid, Curved legs/BWPC1.jpg';
import BWPC2 from '../src/media/HomeDecor/Tray & Platters/Black & White Pyramid, Curved legs/BWPC2.jpg';
import BWPS1 from '../src/media/HomeDecor/Tray & Platters/Black & White Pyramid, Straight legs/BWPS1.jpg'
import BWPS2 from '../src/media/HomeDecor/Tray & Platters/Black & White Pyramid, Straight legs/BWPS2.jpg';
import BWZC1 from '../src/media/HomeDecor/Tray & Platters/Black & White Zig Zag, Curved legs/BWZC1.jpg';
import BWZC2 from '../src/media/HomeDecor/Tray & Platters/Black & White Zig Zag, Curved legs/BWZC2.jpg';
import BWZS1 from '../src/media/HomeDecor/Tray & Platters/Black & White Zig Zag, Straight legs/BWZS1.jpg';
import BWZS2 from '../src/media/HomeDecor/Tray & Platters/Black & White Zig Zag, Straight legs/BWZS2.jpg';
import CD1 from '../src/media/HomeDecor/Tray & Platters/Cake Dome/CD1.jpg';
import CD2 from '../src/media/HomeDecor/Tray & Platters/Cake Dome/CD2.jpg';
import CDS1 from '../src/media/HomeDecor/Tray & Platters/Cake Dome small/CDS1.jpg';
import CDS2 from '../src/media/HomeDecor/Tray & Platters/Cake Dome small/CDS2.jpg';
import MOPS1 from '../src/media/HomeDecor/Tray & Platters/Mother of Pearl/MOPS1.jpg';
import MOPS2 from '../src/media/HomeDecor/Tray & Platters/Mother of Pearl/MOPS2.jpg';
import MZC1 from '../src/media/HomeDecor/Tray & Platters/Multicolored Zig Zag, Curved Legs/MZC1.jpg';
import MZC2 from '../src/media/HomeDecor/Tray & Platters/Multicolored Zig Zag, Curved Legs/MZC2.jpg';
import MZS1 from '../src/media/HomeDecor/Tray & Platters/Multicolored Zig Zag, Staright legs/MZS1.jpg';
import MZS2 from '../src/media/HomeDecor/Tray & Platters/Multicolored Zig Zag, Staright legs/MZS2.jpg';
import LO1 from '../src/media/HomeDecor/Tray & Platters/Trays with Resin Knobs/Large Green/LO1.jpg';
import LO2 from '../src/media/HomeDecor/Tray & Platters/Trays with Resin Knobs/Large Green/LO2.jpg';
import LG1 from '../src/media/HomeDecor/Tray & Platters/Trays with Resin Knobs/Large Orange/LG1.jpg';
import LG2 from '../src/media/HomeDecor/Tray & Platters/Trays with Resin Knobs/Large Orange/LG2.jpg';
import SG1 from '../src/media/HomeDecor/Tray & Platters/Trays with Resin Knobs/Small Green/SG1.jpg';
import SG2 from '../src/media/HomeDecor/Tray & Platters/Trays with Resin Knobs/Small Green/SG2.jpg';
import SO1 from '../src/media/HomeDecor/Tray & Platters/Trays with Resin Knobs/Small Orange/SO1.jpg';
import SO2 from '../src/media/HomeDecor/Tray & Platters/Trays with Resin Knobs/Small Orange/SO2.jpg';
import ACSN1 from '../src/media/HomeDecor/Coasters/Agate Coasters Set of 4 - Natural/ACSN1.jpg' ; 
import ACSN2 from '../src/media/HomeDecor/Coasters/Agate Coasters Set of 4 - Natural/ACSN2.jpg' ; 
import ACSN3 from '../src/media/HomeDecor/Coasters/Agate Coasters Set of 4 - Natural/ACSN3.jpg' ; 
import ACSP1   from '../src/media/HomeDecor/Coasters/Agate Coasters Set of 4 - Pink/ACSP1.jpg' ; 
import ACSP2 from '../src/media/HomeDecor/Coasters/Agate Coasters Set of 4 - Pink/ACSP2.jpg' ; 
import ACSP3 from '../src/media/HomeDecor/Coasters/Agate Coasters Set of 4 - Pink/ACSP3.jpg' ; 
import ACSPU1 from '../src/media/HomeDecor/Coasters/Agate Coasters Set of 4 - Purple/ACSPU1.jpg' ; 
import ACSPU2 from '../src/media/HomeDecor/Coasters/Agate Coasters Set of 4 - Purple/ACSPU2.jpg' ; 
import ACSPU3 from '../src/media/HomeDecor/Coasters/Agate Coasters Set of 4 - Purple/ACSPU3.jpg' ; 
import  ACSW1 from '../src/media/HomeDecor/Coasters/Agate Coasters Set of 4 - White/ACSW1.jpg'
import ACSW2 from '../src/media/HomeDecor/Coasters/Agate Coasters Set of 4 - White/ACSW2.jpg' ; 
import ACSW3 from '../src/media/HomeDecor/Coasters/Agate Coasters Set of 4 - White/ACSW3.jpg' ; 
import MBL1 from '../src/media/HomeDecor/Coasters/Marble Brass Line Inlay Hexagonal Coasters Set of 4/MBL1.jpg';
import MBL2 from '../src/media/HomeDecor/Coasters/Marble Brass Line Inlay Hexagonal Coasters Set of 4/MBL2.jpg';
import MSC1 from '../src/media/HomeDecor/Coasters/Marble Square Coasters with Mother of Pearl Inlay Set of 4/MSC1.jpg';
import MSC2  from '../src/media/HomeDecor/Coasters/Marble Square Coasters with Mother of Pearl Inlay Set of 4/MSC2.jpg';
import MSC3  from '../src/media/HomeDecor/Coasters/Marble Square Coasters with Mother of Pearl Inlay Set of 4/MSC3.jpg';
import MOPC1 from '../src/media/HomeDecor/Coasters/Mother of Pearl Coasters Set of 4/MOPC1.jpg' ; 
import MOPC2 from '../src/media/HomeDecor/Coasters/Mother of Pearl Coasters Set of 4/MOPC2.jpg' ; 
import MOPC3 from '../src/media/HomeDecor/Coasters/Mother of Pearl Coasters Set of 4/MOPC3.jpg' ; 
import MOPC4 from '../src/media/HomeDecor/Coasters/Mother of Pearl Coasters Set of 4/MOPC4.jpg' ; 
import SWM1 from '../src/media/HomeDecor/Coasters/Square White Marble Coasters with Brass Lining Set of 4/SWM1.jpg' ; 
import SWM2 from '../src/media/HomeDecor/Coasters/Square White Marble Coasters with Brass Lining Set of 4/SWM2.jpg' ; 
import SWM3 from '../src/media/HomeDecor/Coasters/Square White Marble Coasters with Brass Lining Set of 4/SWM3.jpg' ; 
import WAA1 from '../src/media/HomeDecor/Coasters/White Marble Square Coasters with Agate Stone Inlay Set of 4/WAA1.jpg' ; 
import WAA2 from '../src/media/HomeDecor/Coasters/White Marble Square Coasters with Agate Stone Inlay Set of 4/WAA2.jpg' ; 
import WAA3 from '../src/media/HomeDecor/Coasters/White Marble Square Coasters with Agate Stone Inlay Set of 4/WAA3.jpg' ; 
import WAP1 from '../src/media/HomeDecor/Coasters/Wood & Marble Square Coasters Set of 4/WAP1.jpg' ; 
import WAP2 from '../src/media/HomeDecor/Coasters/Wood & Marble Square Coasters Set of 4/WAP2.jpg' ; 
import WAP3 from '../src/media/HomeDecor/Coasters/Wood & Marble Square Coasters Set of 4/WAP3.jpg' ; 
import BWZ1 from '../src/media/HomeDecor/Coasters/Set of 6/Wooden Coasters Set of 6 - Black & White Zig-Zag Inlay/BWZ1.jpg' ; 
import BWZ2 from '../src/media/HomeDecor/Coasters/Set of 6/Wooden Coasters Set of 6 - Black & White Zig-Zag Inlay/BWZ2.jpg' ; 
import GWL1 from '../src/media/HomeDecor/Coasters/Set of 6/Wooden Coasters Set of 6 - Grey with White Leaves/GWL1.jpg' ; 
import GWL2 from '../src/media/HomeDecor/Coasters/Set of 6/Wooden Coasters Set of 6 - Grey with White Leaves/GWL2.jpg' ; 
import   WCMOP1 from '../src/media/HomeDecor/Coasters/Set of 6/Wooden Coasters Set of 6 - Mother of Pearl/WCMOP1.jpg' ; 
import  WCMOP2 from '../src/media/HomeDecor/Coasters/Set of 6/Wooden Coasters Set of 6 - Mother of Pearl/WCMOP2.jpg' ; 
import MUL1 from '../src/media/HomeDecor/Coasters/Set of 6/Wooden Coasters Set of 6 - Multicolored Zig-Zag Inlay/MUL1.jpg' ; 
import MUL2 from '../src/media/HomeDecor/Coasters/Set of 6/Wooden Coasters Set of 6 - Multicolored Zig-Zag Inlay/MUL2.jpg' ; 
import NNN1 from '../src/media/HomeDecor/Coasters/Set of 6/Wooden Coasters Set of 6 - Natural Floral Inlay/NNN1.jpg' ; 
import NNN2 from '../src/media/HomeDecor/Coasters/Set of 6/Wooden Coasters Set of 6 - Natural Floral Inlay/NNN2.jpg' ; 
import WAL1 from '../src/media/HomeDecor/others/WALL ART/Agate Semi Precious Stone Wall Art - Dream/WAL1.jpg';
import WAL2 from '../src/media/HomeDecor/others/WALL ART/Agate Semi Precious Stone Wall Art - Dream/WAL2.jpg';
import PEACE1 from '../src/media/HomeDecor/others/WALL ART/Agate Semi Precious Stone Wall Art - Peace/PEACE1.jpg';
import PEACE2 from '../src/media/HomeDecor/others/WALL ART/Agate Semi Precious Stone Wall Art - Peace/PEACE2.jpg';
import VASE1 from '../src/media/HomeDecor/others/Wooden Spiral Design Flower Vase/VASE1.jpg' ; 
import VASE2 from '../src/media/HomeDecor/others/Wooden Spiral Design Flower Vase/VASE2.jpg' ; 

const HomeDecorData = [
    {
        _id: 1,
        image_1: DB1,
        image_2: DB2,
        name: "Dark Brown Candle Stand Set of 2",
        Description: "",
        category: "Candle Stand",
        amazonLink:"https://www.amazon.in/dp/B09P5FJHTB?ref=myi_title_dp"
    
    },
    {
        _id: 2,
        image_1: GR1,
        image_2: GR2,
        name: "Green Candle Stand Set of 3",
        Description: "",
        category: "Candle Stand",
        amazonLink:"https://www.amazon.in/dp/B09B25DQFN?ref=myi_title_dp&th=1"
      
    },
    {
        _id: 3,
        image_1: GY1,
        image_2: GY2,
        name: "Grey Candle Stand Set of 3 ",
        Description: "",
        category: "Candle Stand",
        amazonLink:"https://www.amazon.in/dp/B09JC6J8P8?ref=myi_title_dp"
        
    },
    {
        _id: 4,
        image_1: NAT1,
        image_2: NAT2,
        name: "Natural Candle Stand Set of 3",
        Description: "",
        category: "Candle Stand",
        amazonLink:"https://www.amazon.in/dp/B09JC7HDR3?ref=myi_title_dp"
       
    },
    {
        _id: 5,
        image_1: OWLB1,
        image_2: OWLB2,
        name: "Owl Brown Candle Stand ",
        Description: "",
        category: "Candle Stand",
        amazonLink:"https://www.amazon.in/dp/B08FMB2FT3?ref=myi_title_dp",
    },
    {
        _id: 6,
        image_1: OWLW1,
        image_2: OWLW2,
        name: "Owl White Candle Stand ",
        Description: "",
        category: "Candle Stand",
        amazonLink:"https://www.amazon.in/dp/B08FK8WK7C?ref=myi_title_dp"
    },
    {
        _id: 7,
        image_1: SW1,
        image_2: SW2,
        name: "Single White Candle Stand",
        Description: "",
        category: "Candle Stand",
        amazonLink:"https://www.amazon.in/dp/B08L8VN47Y?ref=myi_title_dp"
    },
    {
        _id: 8,
        image_1: WS1,
        image_2: WS2,
        name: "White Candle Stand Set of 2",
        Description: "",
        category: "Candle Stand",
        amazonLink:"https://www.amazon.in/dp/B08FK8WK7C?ref=myi_title_dp"
    },
    {
        _id: 9,
        image_1: WH1,
        image_2: WH2,
        name: "White Candle Stand Set of 3",
        Description: "",
        category: "Candle Stand",
        amazonLink:"https://www.amazon.in/dp/B08L83TD47?ref=myi_title_dp"
    },
    {
        _id: 10,
        image_1: WW1,
        image_2: WW2,
        name: "White Square Base Candle Stand",
        Description: "",
        category: "Candle Stand",
        amazonLink:"https://www.amazon.in/dp/B08KRJYP6T?ref=myi_title_dp"
    },
    {
        _id: 11,
        image_1: BO1,
        image_2: BO2,
        name: "Silver Bottle Opener",
        Description: "",
        category: "Cutlery",
        amazonLink:"https://www.amazon.in/dp/B0C6FK2P73?ref=myi_title_dp&th=1"
        
    },
    {
        _id: 12,
        image_1: CB1,
        image_2: CB2,
        name: "Brown Cheese Knives Set of 3",
        Description: "",
        category: "Cutlery",
        amazonLink:"https://www.amazon.in/dp/B0CC8Q6KJS?ref=myi_title_dp"
    },
    {
        _id: 13,
        image_1: G1,
        image_2: G2,
        name: "Gold Cheese Knives Set ",
        Description: "",
        category: "Cutlery",
    
    },
    {
        _id: 14,
        image_1: GPV1,
        image_2: GPV2,
        name: "Gold PVD Cheese Knives Set of 3",
        Description: "",
        category: "Cutlery",
        amazonLink:"https://www.amazon.in/dp/B0C6B25QK3?ref=myi_title_dp&th=1"
    },
    {
        _id: 15,
        image_1: GG1,
        image_2: GG2,
        name: "Green Cheese Knives Set of 3",
        Description: "",
        category: "Cutlery",
        amazonLink:"https://www.amazon.in/dp/B0CBXJBCQ5?ref=myi_title_dp"
    },
    {
        _id: 16,
        image_1: RS1,
        image_2: RS2,
        name: "Rose Gold Cheese Knives Set of 3",
        Description: "",
        category: "Cutlery",
        amazonLink:"https://www.amazon.in/dp/B0C6B25QK3?ref=myi_title_dp&th=1"
    },
    {
        _id: 17,
        image_1: BC1,
        image_2: BC2,
        name: "Blue Cutlery with Round Agate Stone Handle",
        Description: "",
        category: "Cutlery",
        amazonLink:"https://www.amazon.in/dp/B0BZDQ8PRN?ref=myi_title_dp"
    },
    {
        _id: 18,
        image_1: LY1,
        image_2: LY2,
        name: "Lemon Yellow Cutlery with Round Agate Stone Handle",
        Description: "",
        category: "Cutlery",
        amazonLink:"https://www.amazon.in/dp/B0CNH8KL4P?ref=myi_title_dp&th=1"
    },
    {
        _id: 19,
        image_1: W1,
        image_2: W2,
        name: "White Cutlery with Round Agate Stone Handle",
        Description: "",
        category: "Cutlery",
        amazonLink:"https://www.amazon.in/dp/B0BZDT3SV7?ref=myi_title_dp&th=1"
    },
    {
        _id: 20,
        image_1: EP1,
        image_2: EP2,
        name: "Electroplated Cutlery Set of 4",
        Description: "",
        category: "Cutlery",
        amazonLink:"https://www.amazon.in/dp/B0CNXDMC9Y?ref=myi_title_dp&th=1"
    },
    {
        _id: 21,
        image_1: NAV1,
        image_2: NAV2,
        name: "Navy Blue Cutlery Set of 4",
        Description: "",
        category: "Cutlery",
        amazonLink:"https://www.amazon.in/dp/B0CNWDXL5Z?ref=myi_title_dp"
    },
    {
        _id: 22,
        image_1: O1,
        image_2: O2,
        name: "Orange Cutlery Set of 4",
        Description: "",
        category: "Cutlery",
        amazonLink:"https://www.amazon.in/dp/B0C53XSDFS?ref=myi_title_dp&th=1"
    },
    {
        _id: 23,
        image_1: F1,
        image_2: F2,
        name: "Gold Finish Serving Cutlery",
        Description: "",
        category: "Cutlery",
        amazonLink:"https://www.amazon.in/dp/B0C6FJR658?ref=myi_title_dp&th=1"
    },
    {
        _id: 24,
        image_1: P1,
        image_2: P2,
        name: "Rose Gold PVD Serving Cutlery",
        Description: "",
        category: "Cutlery",
        amazonLink:"https://www.amazon.in/dp/B0C6FK2P73?ref=myi_title_dp&th=1"
    },
    {
        _id: 25,
        image_1: WO1,
        image_2: WO2,
        name: "Green Round Wood and Resin Handle Cutlery",
        Description: "",
        category: "Cutlery",
        amazonLink:"https://www.amazon.in/dp/B0CM9Q39L4?ref=myi_title_dp"
    },
    {
        _id: 26,
        image_1: OUN1,
        image_2: OUN2,
        name: "Red Round Wood and Resin Handle Cutlery",
        Description: "",
        category: "Cutlery",
        amazonLink:"https://www.amazon.in/dp/B0CM9Q39L4?ref=myi_title_dp&th=1"
    },
    {
        _id: 27,
        image_1: NAP1,
        image_2: NAP2,
        name: "Blue Napkin Rings",
        Description: "",
        category: "Napkin Rings",
        amazonLink:"https://www.amazon.in/dp/B0BRYD7QW5?ref=myi_title_dp"
    },
    {
        _id: 28,
        image_1: NAG1,
        image_2: NAG2,
        name: "Green Napkin Rings",
        Description: "",
        category: "Napkin Rings",
        amazonLink:"https://www.amazon.in/dp/B0BRYDJ184?ref=myi_title_dp"
    },
    {
        _id: 29,
        image_1: NAW1,
        image_2: NAW2,
        name: "White Napkin Rings",
        Description: "",
        category: "Napkin Rings",
        amazonLink:"https://www.amazon.in/dp/B0BRYCL4M2?ref=myi_title_dp"
    },
    {
        _id: 30,
        image_1: PHO1,
        image_2: PHO2,
        name: "Green Photo Frame",
        Description: "",
        category: "Photo Frame",
        amazonLink:"https://www.amazon.in/dp/B09QM8P1H6?ref=myi_title_dp"
    },
    {
        _id: 31,
        image_1: PHW1,
        image_2: PHW2,
        name: "Grey Photo Frame",
        Description: "",
        category: "Photo Frame",
        amazonLink:"https://www.amazon.in/dp/B09QM9FMTT?ref=myi_title_dp"
    },
    {
        _id: 32,
        image_1: PH1,
        image_2: PH2,
        name: "White Photo Frame",
        Description: "",
        category: "Photo Frame",
        amazonLink:"https://www.amazon.in/dp/B09QM8W8W6?ref=myi_title_dp"
    },
    {
        _id: 33,
        image_1: PB1,
        image_2: PB2,
        name: "White with Brown Corners Photo Frame",
        Description: "",
        category: "Photo Frame",
        amazonLink:"https://www.amazon.in/dp/B09QM8XMNC?ref=myi_title_dp"
        
    },
    {
        _id: 34,
        image_1: PHS1,
        image_2: PHS2,
        name: "White with Brown Stripes Photo Frame",
        Description: "",
        category: "Photo Frame",
        amazonLink:"https://www.amazon.in/dp/B09QM9NYNS?ref=myi_title_dp"
    },
    {
        _id: 35,
        image_1: SB1,
        image_2: SB2,
        name: "Blue & White Geometric Salad Bowl",
        Description: "",
        category: "Salad Bowl",
        amazonLink:"https://www.amazon.in/dp/B0CR4C7B7F?ref=myi_title_dp&th=1"
    },
    {
        _id: 36,
        image_1: SBE1,
        image_2: SBE2,
        name: "Emerald Green Leaves Salad Bowl",
        Description: "",
        category: "Salad Bowl",
        amazonLink:"https://www.amazon.in/dp/B0CR4BWDSB?ref=myi_title_dp&th=1"
    },
    {
        _id: 37,
        image_1: SBG1,
        image_2: SBG2,
        name: "Green Paradise Salad Bowl",
        Description: "",
        category: "Salad Bowl",
        amazonLink:"https://www.amazon.in/dp/B0CR4C7B7F?ref=myi_title_dp"
    },
    {
        _id: 37,
        image_1: SBZ1,
        image_2: SBZ2,
        name: "White with Blue and Green Leaves",
        Description: "",
        category: "Salad Bowl",
        amazonLink:"https://www.amazon.in/dp/B0B9T2TB4Y?ref=myi_title_dp"
    },
    
    {
        _id: 39,
        image_1: TBG1,
        image_2: TBG2,
        name: "Green Tissue Box",
        Description: "",
        category: "Tissue Box",
        amazonLink:"https://www.amazon.in/dp/B0BTTHJ61H?ref=myi_title_dp&th=1"
    },
    {
        _id: 40,
        image_1: TH1,
        image_2: TH2,
        name: "Grey Tissue Box",
        Description: "",
        category: "Tissue Box",
        amazonLink:"https://www.amazon.in/dp/B0BTTJDPFC?ref=myi_title_dp"
    },
    {
        _id: 41,
        image_1: TMP1,
        image_2: TMP2,
        name: "Mother of Pearl Tissue Box",
        Description: "",
        category: "Tissue Box",
        amazonLink:"https://www.amazon.in/dp/B08HHCKJKQ?ref=myi_title_dp"
    },
    {
        _id: 42,
        image_1: TSH1,
        image_2: TSH2,
        name: "Square Holder Tissue Box",
        Description: "",
        category: "Tissue Box",
        amazonLink:"https://www.amazon.in/dp/B0C9JSG5RV?ref=myi_title_dp"
    },
    {
        _id: 43,
        image_1: TT1,
        image_2: TT2,
        name: "White Tissue Box",
        Description: "",
        category: "Tissue Box",
        amazonLink:"https://www.amazon.in/dp/B0BTTKB51G?ref=myi_title_dp&th=1"
    },
    {
        _id: 44,
        image_1: BWPS1,
        image_2: BWPS2,
        name: "Black & White Pyramid Tray with Straight Legs",
        Description: "",
        category: "Tray & Platters",
        amazonLink:"https://www.amazon.in/dp/B0D31W64V7?ref=myi_title_dp"
    },
    {
        _id: 44,
        image_1: BWPC1,
        image_2: BWPC2,
        name: "Black & White Pyramid Tray with Curved Legs",
        Description: "",
        category: "Tray & Platters",
        amazonLink:"https://www.amazon.in/dp/B0D31VM311?ref=myi_title_dp"
    },
    {
        _id: 45,
        image_1: BWZC1,
        image_2: BWZC2,
        name: "Black & White Zig Zag Tray with Curved Legs",
        Description: "",
        category: "Tray & Platters",
        amazonLink:"https://www.amazon.in/dp/B0D31T4SJC?ref=myi_title_dp&th=1"
    },
    {
        _id: 46,
        image_1: BWZS1,
        image_2: BWZS2,
        name: "Black & White Zig Zag Tray with Straight Legs",
        Description: "",
        category: "Tray & Platters",
        amazonLink:"https://www.amazon.in/dp/B0D31T4SJC?ref=myi_title_dp&th=1"
    },
    {
        _id: 47,
        image_1: CD1,
        image_2: CD2,
        name: "Cake Dome Tray",
        Description: "",
        category: "Tray & Platters",
        amazonLink:"https://www.amazon.in/dp/B09W9ZHBGK?ref=myi_title_dp"
    },
    {
        _id: 48,
        image_1: CDS1,
        image_2: CDS2,
        name: "Small Cake Dome Tray",
        Description: "",
        category: "Tray & Platters",
        amazonLink:"https://www.amazon.in/dp/B08QDBPTKG?ref=myi_title_dp"
    },
    {
        _id: 49,
        image_1: MOPS1,
        image_2: MOPS2,
        name: "Mother of Pearl Tray",
        Description: "",
        category: "Tray & Platters",
        amazonLink:"https://www.amazon.in/dp/B08J4F1DY1?ref=myi_title_dp"
    },
    {
        _id: 50,
        image_1: MZC1,
        image_2: MZC2,
        name: "Multicolored Zig Zag Tray with Curved Legs",
        Description: "",
        category: "Tray & Platters",
        amazonLink:"https://www.amazon.in/dp/B0D31TQNFQ?ref=myi_title_dp"
    },
    {
        _id: 51,
        image_1: MZS1,
        image_2: MZS2,
        name: "Multicolored Zig Zag Tray with Straight Legs",
        Description: "",
        category: "Tray & Platters",
        amazonLink:"https://www.amazon.in/dp/B0D31TVTCS?ref=myi_title_dp"
    },
    {
        _id: 52,
        image_1: LO1,
        image_2: LO2,
        name: "Large Green Tray with Resin Knobs",
        Description: "",
        category: "Tray & Platters",
        amazonLink:"https://www.amazon.in/dp/B0C8DJC7QH?ref=myi_title_dp"
    },
    {
        _id: 53,
        image_1: LG1,
        image_2: LG2,
        name: "Large Orange Tray with Resin Knobs",
        Description: "",
        category: "Tray & Platters",
        amazonLink:"https://www.amazon.in/dp/B0C8DMTPSX?ref=myi_title_dp&th=1"
    },
    {
        _id: 54,
        image_1: SG1,
        image_2: SG2,
        name: "Small Green Tray with Resin Knobs",
        Description: "",
        category: "Tray & Platters",
        amazonLink:"https://www.amazon.in/dp/B0C8DMLHP4?ref=myi_title_dp&th=1"
    },
    {
        _id: 55,
        image_1: SO1,
        image_2: SO2,
        name: "Small Orange Tray with Resin Knobs",
        Description: "",
        category: "Tray & Platters",
        amazonLink:"https://www.amazon.in/dp/B0C8DJWP8M?ref=myi_title_dp"
    },    {
        _id: 56,
        image_1: ACSN1,
        image_2: ACSN2,
        image_3: ACSN3, 
        name: "Agate Coasters Set of 4-Natural",
        Description: "",
        category: "Coasters",
        amazonLink:"https://www.amazon.in/dp/B07J4H6GWZ?ref=myi_title_dp"
    },
    {
        _id: 57,
        image_1: ACSP1,
        image_2: ACSP2,
        image_3: ACSN3, 
        name: "Agate Coasters Set of 4-Pink",
        Description: "",
        category: "Coasters",
        amazonLink:"https://www.amazon.in/dp/B07J54V6KT?ref=myi_title_dp"
    },
    {
        _id: 58,
        image_1: ACSPU1,
        image_2: ACSPU2,
        image_3: ACSPU3, 
        name: "Agate Coasters Set of 4-Purple",
        Description: "",
        category: "Coasters",
        amazonLink:"https://www.amazon.in/dp/B07V7TPGQL?ref=myi_title_dp"
    },
    {
        _id: 59,
        image_1: ACSW1 ,
        image_2: ACSW2,
        image_3: ACSW3, 
        name: "Agate Coasters Set of 4-White",
        Description: "",
        category: "Coasters",
        amazonLink:"https://www.amazon.in/dp/B07V2G6BFL?ref=myi_title_dp"
    },
    {
        _id: 60,
        image_1: MBL1 ,
        image_2: MBL2,
        name: "Marble Brass Line Inlay Hexagonal Coasters Set of 4",
        Description: "",
        category: "Coasters",
        amazonLink:"https://www.amazon.in/dp/B08KPD4KCY?ref=myi_title_dp"
    },
    {
        _id: 61,
        image_1: MSC1 ,
        image_2: MSC2,
        name: "Marble Square Coasters with Mother of Pearl Inlay Set of 4",
        Description: "",
        category: "Coasters",
        amazonLink:"https://www.amazon.in/dp/B08GKCJGKD?ref=myi_title_dp"
    },
    {
        _id: 62,
        image_1: MOPC1 ,
        image_2: MOPC2,
        name: "Mother of Pearl Coasters Set of 4.",
        Description: "",
        category: "Coasters",
        amazonLink:"https://www.amazon.in/dp/B07QGVGFPZ?ref=myi_title_dp"
    },
    {
        _id: 63,
        image_1: BWZ1 ,
        image_2: BWZ2,
        name: "Black and White Zig-Zag Inlay Wooden Coasters Set of 6.",
        Description: "",
        category: "Coasters",
        amazonLink:"https://www.amazon.in/dp/B0B5255C1C?ref=myi_title_dp"
    },
    {
        _id: 64,
        image_1: GWL1 ,
        image_2: GWL2,
        name: "Grey with White Leaves Wooden Coasters Set of 6.",
        Description: "",
        category: "Coasters",
        amazonLink:"https://www.amazon.in/dp/B0B52B8M3B?ref=myi_title_dp&th=1"
    },  {
        _id: 65,
        image_1: WCMOP1 ,
        image_2: WCMOP2,
        name: "Mother of Pearl Wooden Coasters Set of 6.",
        Description: "",
        category: "Coasters",
        amazonLink:"https://www.amazon.in/dp/B0B526PT47?ref=myi_title_dp"
    },
    {
        _id: 66,
        image_1: MUL1 ,
        image_2: MUL2,
        name: "Multicolored Zig-Zag Inlay Wooden Coasters Set of 6.",
        Description: "",
        category: "Coasters",
        amazonLink:"https://www.amazon.in/dp/B0B529QDF4?ref=myi_title_dp"
    },
    {
        _id: 67,
        image_1: NNN1 ,
        image_2: NNN2,
        name: "Natural Floral Inlay Wooden Coasters Set of 6.",
        Description: "",
        category: "Coasters",
        amazonLink:"https://www.amazon.in/dp/B0B5269GY2?ref=myi_title_dp&th=1"
    },
    {
        _id: 67,
        image_1: SWM1 ,
        image_2: SWM2,
        name: "Square White Marble Coasters with Brass Lining Set of 4 . ",
        Description: "",
        category: "Coasters",
        amazonLink:"https://www.amazon.in/dp/B08GKB27BZ?ref=myi_title_dp"
    },
    {
        _id: 67,
        image_1: WAA1 ,
        image_2: WAA2,
        name: " White Marble Square Coasters with Agate Stone Inlay Set of 4 . ",
        Description: "",
        category: "Coasters",
        amazonLink:"https://www.amazon.in/dp/B08HH9BMN8?ref=myi_title_dp"
    },
    {
        _id: 68,
        image_1: WAP1 ,
        image_2: WAP2,
        name: " Wood & Marble Square Coasters Set of 4 .",
        Description: "",
        category: "Coasters",
        amazonLink:"https://www.amazon.in/dp/B08MT78NQX?ref=myi_title_dp"
    },
    {
        _id: 69,
        image_1: WAL1 ,
        image_2: WAL2,
        name: " Agate Semi Products Stone Wall Art - Dream",
        Description: "",
        category: "Others",
        amazonLink:"https://www.amazon.in/dp/B08KWLXVTH?ref=myi_title_dp"
    },
    {
        _id: 70,
        image_1: PEACE1 ,
        image_2: PEACE2,
        name: " Agate Semi Products Stone Wall Art - Peace ",
        Description: "",
        category: "Others",
        amazonLink:"https://www.amazon.in/dp/B08KWQ2W4H?ref=myi_title_dp"
    },
    {
        _id: 71,
        image_1: VASE1 ,
        image_2: VASE2,
        name: " Wooden Spiral Design Flower Vase ",
        Description: "",
        category: "Others",
        amazonLink:"https://www.amazon.in/dp/B09P8TQP4K?ref=myi_title_dp"
    },
   
    
    
    
];

export default HomeDecorData;
